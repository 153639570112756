import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { useAppDispatch} from '../../app/hooks';

import viewInfluencer from '../../features/viewcompany'


const LoggedOutIcons = ({photo, influencer_number}) => {
    const dispatch = useAppDispatch()

    return (
        <div>
            <Tooltip title="Influencers">
                <Link to='/follow' onClick={() => {dispatch(viewInfluencer('1'))}} style={{ textDecoration: 'none' }}>
                <IconButton color="inherit">
                    <Badge 
                        badgeContent={influencer_number} 
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                    <Avatar 
                        alt="Influencers"
                        src={process.env.PUBLIC_URL + '/img' + photo}
                        size="large"
                    />
                    </Badge>
                </IconButton>
                </Link>
            </Tooltip>
        </div>
    )
}

export default LoggedOutIcons