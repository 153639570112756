import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import BadgeDialog from '../badge/BadgeDialog'
import Confetti from '../badge/Confetti'
import apit from '../api/authApi';
import { set } from 'react-hook-form';


// import api from '../api/companies';


const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        width: '100%',
        display: "block",
    },
    errorMsg: {
        color: 'red',
        textAlign: 'left',
        lineHeight: '0',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }
})

const NewCompanyInputA = ({ initialValues }) => {
  const classes = useStyles()
  const [formValues, setFormValues] = useState(initialValues);
  const [viewNewBadge,setViewNewBadge]=useState(false);
  const [newBadgeData,setNewBadgeData]=useState({});
  const [errors, setErrors] = useState({});
  const [showDanger,setShowDanger]=useState(false)
  const [serverResponse,setServerResponse]=useState('')

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    const errors = {};
    if (!formValues.title) {
      errors.title = 'Company Title is required';
    }
    if (!formValues.name_view) {
      errors.name_view = 'Name view is required';
    }

    if (!formValues.price) {
      errors.price = 'Merchandice Price is required';
    }
    if (!formValues.influencer_usd) {
      errors.influencer_usd = 'Finders Fee is required';
    }

    if (Object.keys(errors).length > 0) {
      console.log("errors")
      console.log(errors)
      setErrors(errors)
      return;
    }

    console.log(formValues)

    const postNewCompany = async () => {
      try {
        const response = await apit.post(`/api/company/`,formValues);
          console.log(response)
          // setInfluencerPhoto(response.data.picture_location)
          window.scrollTo(0,0)
          // updateBadges(response.data)
          setViewNewBadge(true)
          handleReset()
          // setFormValues(initialValues);
          setErrors({});
      } catch (err) {
        if (err.response.status===400) {
          setShowDanger(true)
          setServerResponse(err.response.data.message)
        } else if (err.response) {
          setShowDanger(true)
          setServerResponse(err.response.data.message)
        } else {
          console.log(`error: ${err.message}`)
          setShowDanger(true)
          setServerResponse(err.message)

        }

      }
    }
    postNewCompany();
  };

  const handleReset = () => {
    setFormValues(initialValues);
    setErrors({});
  };

  const closeBadgeDialog = () => {
    // setUpdate(!update)
    setNewBadgeData({})
    setViewNewBadge(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <TextField
          type="text"
          name="name_view"
          label="Company Name"
          value={formValues.name_view}
          variant="outlined"
          className={classes.field}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.name_view && <span>{errors.name_view}</span>}
        </div>
      </div>  
      <div>
        <TextField
          type="text"
          name="title"
          label="Company Title"
          value={formValues.title}
          variant="outlined"
          className={classes.field}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.title && <span style={{ textAlign: 'left' }}>{errors.title}</span>}
        </div>
      </div>
      <div>
        <TextField
          type="url"
          label="Official Website"
          name="official_website"
          className={classes.field}
          variant="outlined"
          value={formValues.official_website}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.official_website && <span>{errors.officaial_website}</span>}
        </div>
      </div>
      <div>
        <TextField
          type="number"
          label="Merchandice Price"
          name="price"
          className={classes.field}
          variant="outlined"
          value={formValues.price}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.price && <span>{errors.price}</span>}
        </div>
      </div>
      <div>
        <TextField
          type="number"
          label="Finders Fee Percent"
          name="influencer_usd"
          className={classes.field}
          variant="outlined"
          value={formValues.influencer_usd}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (value >= 0 && value <= 95) {
              handleInputChange(e);
            }
          }}
          inputProps={{ min: 0, max: 95 }}
        />
        <div className={classes.errorMsg}>
          {errors.influencer_usd && <span style={{ textAlign: 'left' }}>{errors.influencer_usd}</span>}
        </div>
      </div>
      {/* <div>
        <TextField
          type="number"
          label="Finders Fee percent"
          name="influencer_usd"
          className={classes.field}
          variant="outlined"
          value={formValues.influencer_usd}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.influencer_usd && <span style={{ textAlign: 'left' }}>{errors.influencer_usd}</span>}
        </div>
      </div> */}
      <div>
        <TextField
          multiline
          minRows={4}
          label="Company Description"
          name="description"
          className={classes.field}
          variant="outlined"
          value={formValues.description}
          onChange={handleInputChange}
        />
        <div
            className={classes.errorMsg}
        >
            {errors.description && <span>{errors.description}</span>}
        </div>
      </div>
      <br></br>
      {showDanger?
          <Box>
              <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                  {serverResponse}
              </Alert>
              <br></br>
          </Box>
          :
          ''
      }
      <Button type="submit" variant="contained" className={classes.btn} sx={{ mr: '15px'}}>
        Submit
      </Button>
      <Button type="button" variant="contained" className={classes.btn} onClick={handleReset}>
        Reset
      </Button>
      {viewNewBadge?
        <div>
          <BadgeDialog open={viewNewBadge} data={newBadgeData} onClose={()=>{closeBadgeDialog()}}/>
          <Confetti open={viewNewBadge? true:false}/>
        </div>
        
        :
        <div></div>
      }
    </form>
  );
};

export default NewCompanyInputA;