import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Wish from './Wish'
import apit from '../api/authApi';
import { useAppDispatch, useAppSelector } from '../../app/hooks';


const LoggedInCart=()=>{
    const [isLoading, setIsLoading] = useState(true);                                              
    const [orders,setOrders]=useState([]);

    useEffect(()=> {
        getCarts()

    },[]);


    const getCarts = () => {
        try {
            apit.get('/api/order/cart').then(data => {
                console.log(data.data)
                setOrders(data.data)
            })
        } catch (error) {
            console.error("Error deleting order:", error);
          }
    }

    return (
        <Paper elevation={5} className="App-paper-full">
            <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center">Cart</Typography>
                    </Grid>
                    
                    {
                        
                        orders.map(
                            (order,index)=>(
                                <Grid item xs={12}>
                                    <Wish key={index}
                                        id={order.id}
                                        getCarts={() => getCarts()}
                                        show_order={order}
                                    />
                                </Grid>
                            )
                        )
                    }
                        
                    
                    <br></br>
                    <Grid item xs={12}>
                        <Link to='/checkout' style={{ textDecoration: 'none' }}>
                            <Button 
                                sx={{ mt: 1, mr: 1,width: '100%' }} 
                                type="submit" 
                                variant="contained" 
                            >
                                Checkout
                            </Button>
                        </Link>
                    </Grid> 
                </Grid>
        </Paper>
    )
}

const CartPage = () => {
    const isAuth = useAppSelector((state) => state.loginstat.value)
    

    return (
        <div>
            {isAuth?
                <LoggedInCart />
            :
                <Paper elevation={5} className="App-paper-full">
                    Please Login to access this page
                </Paper>
            }
        </div>
    )
}


export default CartPage