import { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import apit from '../api/authApi';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  navlogosm: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const Selections = ({setSelection, ship_id}) => {
    const [value, setValue] = useState('')
    const [selections, setSelections] = useState([])
    const classes = useStyles()

    // useEffect(()=> {
    //     setSelection(value)
    //   },[]);

    useEffect(()=> {
        const getAttributeSelections = async () => {
        
            try {
                const body = {shipment_id: ship_id}
                const response = await apit.post(`/api/order/calcshipment`,body).then(data => {
                    setSelections(data.data)
                    })
                    .catch(error => console.log(error));
            } catch (err) {
                console.log(err)
            }
        }
        getAttributeSelections();
      },[ship_id]);

    const handleChange = (event) => {
        console.log('test')
        const selectedObjectId = event.target.value;
        console.log(selectedObjectId);
        setValue(selectedObjectId);
        // setSelection(event.target.value)
        console.log('test')
        console.log(event.target.value);
        const selectedOption = selections.find(
            (selection) => selection.object_id === selectedObjectId
        );
        console.log(selectedOption.amount)
        if (selectedOption) {
            setSelection(selectedOption.amount);
        }
      };


    return (

            <RadioGroup
                row
                name='Rate'
                value={value}
                onChange={handleChange}
            >
                    {
                        selections.map(
                            (selection,index)=>(
                                <Grid 
                                    container
                                    justifyContent="space-between"
                                    justify="center"
                                    alignitems="center"
                                    sx={{width: '100%'}}
                                    key={selection.object_id} 
                                >
                                <Grid item xs={12} sx={{display: 'flex', alignItems: 'center' }} key={index}>
                                    <FormControlLabel value={selection.object_id} control={<Radio />} label={
                                        <Box sx={{ width: '100%'}}>              
                                            <Grid item xs={12} alignitems="left" sx={{width: '100%'}}>
                                                <Typography variant="body1" fontWeight="bold"  sx={{ textAlign: 'left', width: '100%' }}>
                                                    ${selection.amount}
                                                </Typography>
                                            </Grid>
                                         </Box>
                                    }
                                    sx={{marginLeft: '5px', width: '8rem', height: '1.5rem'}}
                                    />
                                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center' }} key={index}>
                                        <Typography variant="body2"  sx={{ textAlign: 'left', width: '100%' }}>{selection.provider}</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{display: 'flex', alignItems: 'center' }} key={index}>
                                        <Typography variant="body2" color="textSecondary"  sx={{ textAlign: 'left', width: '100%' }}>
                                            {selection.duration_terms}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                </Grid>
                                
                            )
                        )
                    }
            </RadioGroup>
                
    )
}


export default Selections