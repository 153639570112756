import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import useRoles from '../auth/useRoles'
import Company from '../company/Company'
import Typography from '@mui/material/Typography';
import { Paper, Grid } from '@mui/material';
import ListProducts from '../company/ListProducts'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { followinfluencer } from '../../features/followinfluencer';
import { followInfluencerSet } from '../../features/followinfluencer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import api from '../api/companies';

const Influencer = ({SelectProduct,update}) => {
    const [products,setProducts]=useState([]);
    const [gifts,setGifts]=useState([]);
    const viewInfluencer = useAppSelector((state) => state.followinfluencer)
    const dispatch = useAppDispatch()

    // const [companies,setCompanies]=useState([{
    //     "id":1,
    //     "logo":"/default.jpg",
    //     "name_code":"brackett_innovations",
    //     "name_view":"Brackett Innovations",
    //     "picture":"/default.jpg",
    //     "price":1000000,
    //     "title":"Your favorate app",
    //     "short_description":"Your favorate app",
    //     "primary_color":'#366C4D',
    //     "secondary_color":'#006400',
    //     "review":5.0,
    // }]);
    // const navigate = useNavigate();
    // const dispatch = useAppDispatch()
    // dispatch(loginLoad())
//   const follow = useAppSelector((state) => state.followinfluencer.picture_location)
//   const [featuredCompany,setFeaturedCompany]=useState(
//         {
//             "id":1,
//             "logo":"/default.jpg",
//             "name_code":"brackett_innovations",
//             "name_view":"Brackett Innovations",
//             "picture":"/default.jpg",
//             "price":1000000,
//             "title":"Your favorate app",
//             "short_description":"Your favorate app",
//             "primary_color":'#366C4D',
//             "secondary_color":'#006400',
//             "review":5.0,
//         }
//     );
//     const [viewFeatured,setViewFeatured]=useState(null);
//     const [influencer,setInfluencer]=useState(
//         {"picture_location": "/default.jpg",
//         "username": "default",
//         "views": 0,
//         "id":1}
//     );
    // const [openDeleteConnection,setOpenDeleteConnection]=useState(false);
    // const roles = useRoles()
    // const [logged]=false

    useEffect(()=> {

        const pathSegments = window.location.pathname.split('/');
        const id = pathSegments.pop();
        console.log(id)
        // localStorage.setItem('Influencer', id);
        try {
            const response = api.get(`/api/stakeholder/infgift/${id}`);
        } catch (err) {
            console.log(err)
            if (err.response) {
                console.log(err.response.data)
                console.log(err.response.status)
            } else {
                console.log(`error: ${err.message}`)
    
            }
        }
        const getInfluencers = async () => {
        
            try {
                const response = await api.get(`/api/stakeholder/influencer/${id}`);
                    dispatch(followInfluencerSet(response.data))
            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
       
                }
            }
        }

        const getProducts = async () => {
            
            try {
                const response = await api.put(`/api/stakeholder/influencer/${id}`);
                    setProducts(response.data)
                    console.log(response.data)

            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
        
                }
            }
        }

        const getGifts = async () => {
            
            try {
                const response = await api.put(`/api/stakeholder/infgift/${id}`);
                    setGifts(response.data)
            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
        
                }
            }
        }
        if (viewInfluencer.user_entity_id != id) {
            getInfluencers();
            dispatch(followInfluencerSet(id));
        }
        getProducts()
        getGifts()

        // update()
            
    },[]);


    // useEffect(()=> {
    //     SelectCompany('brackettinnovations')
    //     const pathSegments = window.location.pathname.split('/');
    //     dispatch(viewproductSet(product));



    //     // const requestOptions = {
    //     //     method: "PUT",
    //     //     headers: {
    //     //         'content-type': "application/json"
    //     //     }
    //     // }

    //     // if (logged) {
    //     //     console.log(logged)
    //     //     authFetch(`/api/influencer/connect/${id}`, requestOptions)
    //     //     .then(function(res) {
    //     //         if (res.status === 200) {
    //     //             return res.json();
    //     //         } else {
    //     //             throw new Error('Connection went bad');
    //     //         }
    //     //     })
    //     //     .then(data => {
    //     //         update()
    //     //     })
    //     //     .catch(err=>console.log(err))
    //     // } else {
    //     //     console.log(logged)
    //     //     fetch(`/api/influencer/${id}`, requestOptions)
    //     //     .then(function(res) {
    //     //         if (res.status === 200) {
    //     //             return res.json();
    //     //         } else {
    //     //             throw new Error('Connection went bad');
    //     //         }
    //     //     })
    //     //     .then(data => {
    //     //     })
    //     //     .catch(err=>console.log(err))      
    //     // }
            
    // },[logged]);

    // useEffect(()=> {
    //     // let id = window.location.href.toString().split('/')[4]
    //     const pathSegments = window.location.pathname.split('/');
    //     const id = pathSegments.pop();

        // const requestOptions = {
        //     method: "GET",
        //     headers: {
        //         'content-type': "application/json"
        //     }
        // }

        // fetch(`/api/influencer/${id}`, requestOptions)
        //     .then(function(res) {
        //         if (res.status === 200) {
        //             return res.json();
        //         } else {
        //             throw new Error()
        //         }
        //     })
        //     .then(data => {
        //         setInfluencer(data)
        //     })
        //     .catch(err=>console.log(err))

    //         const params = new URLSearchParams(window.location.search)
    //         const q= params.get('featured_company');
    //         {q ?
    //             setViewFeatured(q)             
    //         :
    //             setViewFeatured(influencer.featured_company)
    //         }

    //         if (viewFeatured) {

    //             // fetch(`/api/company/id/${viewFeatured.toString()}`, requestOptions)
    //             // .then(function(res) {
    //             //     if (res.status === 200) {
    //             //         return res.json();
    //             //     } else {
    //             //         throw new Error()
    //             //     }
    //             // })
    //             // .then(data => {
    //             //     console.log(data)
    //             //     setFeaturedCompany(data)
    //             // })
    //             // .catch(err=>console.log(err))
    //         }
    // },[setCompanies]);

    // useEffect(()=> {
        
        // const requestOptions = {
        //     method: "GET",
        //     headers: {
        //         'content-type': "application/json"
        //     }
        // }
            
        // if (viewFeatured) {

        //     fetch(`/api/company/id/${viewFeatured.toString()}`, requestOptions)
        //     .then(function(res) {
        //         if (res.status === 200) {
        //             return res.json();
        //         } else {
        //             throw new Error()
        //         }
        //     })
        //     .then(data => {
        //         setFeaturedCompany(data)
        //     })
        //     .catch(err=>console.log(err))
        // }
    // },[viewFeatured]);

    

    // const StoreCompany = (id,name) => {
    //     SelectCompany(name)
    // }

    // const deleteConnection = () => {
    //     const pathSegments = window.location.pathname.split('/');
    //     const id = pathSegments.pop();
    //     localStorage.setItem('Influencer', '1');


    //     const requestOptions = {
    //         method: "DELETE",
    //         headers: {
    //             'content-type': "application/json"
    //         }
    //     }

    //     authFetch(`/api/influencer/connect/${id}`, requestOptions)
    //         .then(function(res) {
    //             return res.json();
    //         })
    //         .then(data => {
    //             console.log(data)
    //             update()
    //             navigate('/influencer')
    //         })
    //         .catch(err=>console.log(err))
    //         setOpenDeleteConnection(false);

    //     // navigate('/influencer')
    // }

    // const handleOpen = () => {
    //     setOpenDeleteConnection(true);
    //   };
    
    // const handleClose = () => {
    //     setOpenDeleteConnection(false);
    //   };

    return (
        <div className="home container">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Paper elevation={5} className="App-paper" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                        <Avatar 
                            src={process.env.PUBLIC_URL + '/img' + viewInfluencer.picture_location}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Typography variant="h5" sx={{ marginTop: '10px' }}>
                            {viewInfluencer.username.toUpperCase()}
                        </Typography>
                        <Grid container justifyContent="center" alignItems="center" >
                            <Grid item xs={6}>
                                <Typography variant="body2" sx={{ marginTop: '10px' }}>
                                    Views: {viewInfluencer.views}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" sx={{ marginTop: '10px' }}>
                                    Company Influenced: {viewInfluencer.row_count}
                                </Typography>
                            </Grid>
                        </Grid>
                    {/* {logged?<Button onClick={handleOpen}>Unfollow</Button>:<></>} */}
                    </Paper>
                </Grid>
                 
              {/* {featuredCompany.name_view!=="Brackett Innovations"?
                    <Grid item xs={12}>
                        <Paper elevation={5} className="App-paper-full" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                            <Typography variant="h5" sx={{ marginTop: '10px' }}>
                                Featured Company
                            </Typography>
                        </Paper>
                            <Link
                                // onClick={()=>{StoreCompany(featuredCompany.id,featuredCompany.name_code)}}
                                style={{ textDecoration: 'none' }}
                                to={`/company/${featuredCompany.name_code}`}
                            >
                                <Grid item xs={12}>
                                    <Company
                                        logo={featuredCompany.logo}
                                        title={featuredCompany.title}
                                        name_view={featuredCompany.name_view}
                                        cover={featuredCompany.picture}
                                        description={featuredCompany.short_description}
                                        primary_color={featuredCompany.primary_color}
                                        review={featuredCompany.review}
                                        review_total={featuredCompany.review_total}
                                        price={featuredCompany.price}
                                        secondary_color={featuredCompany.secondary_color}
                                    />
                                </Grid>
                            </Link> 
                    </Grid>
                :
                    <>
                    </>
                }  */}
                   <Grid item xs={12}>
                        <Paper elevation={5} className="App-paper-full" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                            <Typography variant="h5" sx={{ marginTop: '10px' }}>
                                Products
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <ListProducts products={products} SelectProduct={SelectProduct} />
                    </Grid>
                
                 {/* {
                    companies.map(
                        (company,index)=>(
                        <Link
                            // onClick={()=>{StoreCompany(company.id,company.name_code)}}
                            style={{ textDecoration: 'none' }}
                            to={`/company/${company.name_code}`}
                        >
                            <Grid item xs={12}>
                                <Company key={index}
                                    logo={company.logo}
                                    title={company.title}
                                    name_view={company.name_view}
                                    cover={company.picture}
                                    description={company.short_description}
                                    primary_color={company.primary_color}
                                    review={company.review}
                                    review_total={company.review_total}
                                    price={company.price}
                                    secondary_color={company.secondary_color}
                                />
                            </Grid>
                        </Link>
                        )
                    )
                } */}
                <Grid item xs={12}>
                    <Paper elevation={5} className="App-paper-full" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                        <Typography variant="h5" sx={{ marginTop: '10px' }}>
                            Gifted Products
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <ListProducts products={gifts} SelectProduct={SelectProduct}/>
                </Grid>
                    
                
                <br></br>
            </Grid>
            {/* <Dialog
                open={openDeleteConnection}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                Stop following {influencer.username}?
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    Are you sure you want to no longer follow {influencer.username}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=>deleteConnection()} autoFocus>
                    Unfollow
                </Button>
                </DialogActions>
            </Dialog> */}
            <br></br>
            {/* {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/influencer/"/>
            </div>
            :
                ""
                } */}
        </div>
    )
}


export default Influencer