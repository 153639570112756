import { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import api from '../api/companies';
import MerchandiceCheckbox from './MerchandiceCheckbox'
import ProductSelector from './ProductSelecter'
import { uploadCartItem } from '../../features/cartlist';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block",
        maxWidth: '120px'
        },
    labelPlacementStart: {
        justifyContent: 'space-between'
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogolg: {
        color: '#9BCDB0',
        maxWidth: '230px',
        marginRight: '.5em',
        marginTop: '4%',
        maxHeight: '250px',
      },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
    root: {
        // component default is "inline-flex", using "flex" makes the
        // label + control group use the entire width of the parent element
        display: "flex",
        // component default is "flex-start", using "space-between" pushes
        // both flexed content to the right and left edges of the flexbox
        // Note: the content is aligned to the right by default because
        // the 'labelPlacement="start"' component prop changes the flexbox
        // direction to "row-reverse"
        justifyContent: "space-between",
    },
})

const label = { inputProps: { 'aria-label': 'Checkboxgift' } };

const BuyProduct = ({product_id, color, updateQuantityMax, group_product=product_id, quantity=1}) => {
    const [loadingData, setLoadingData] = useState(true);                                                                                                                                        
    const [merchs, setMerchs] = useState('')
    const cartList = useAppSelector((state) => state.cartlist)

    const dispatch = useAppDispatch()
    const classes = useStyles()

    useEffect(()=> {
        // const getProductAccessories = async () => {
        
        //     try {
        //         const response = await api.get(`/api/order/merchandice/products/${product_id}`).then(data => {
        //             setAccessories(data.data)
        //             })
        //             .catch(error => console.log(error));
        //     } catch (err) {
        //         console.log(err)
        //     }
        // }
        // getProductAccessories();
        setLoadingData(true)
        getProductInitValue();
        setLoadingData(false)

      },[product_id]);


      const getProductInitValue = async () => {
        try {
            const response = await api.get(`/api/order/init/product/${product_id}/${group_product}`).then(data => {
                setMerchs(data.data)
                setWishMerch(data.data,product_id)
                })
                .catch(error => console.log(error));
        } catch (err) {
            console.log(err)
        }
    }

    const getSelectedMerch = async (value,attribute,old_merch,manufactured_output) => {
        const body = {
            selection: value,
            merch_id: old_merch,
            attribute_id: attribute,
            manufactured_output: manufactured_output
        }
        
        try {
            const response = await api.put(`/api/order/init/product/${product_id}/${group_product}`,body).then(data => {

                setMerchs(data.data)
                setWishMerch(data.data,product_id)

                })
                .catch(error => console.log(error));
        } catch (err) {
            console.log(err)
        }
    }

    const setWishMerch = (data, key_value) => {
        // the selections left and what is their quantity so you can default ones with 0 quantity
        let newCheckedMerch = {
            [String(key_value)]: { 
                id: data.merch_id,
                name_view: data.name_view,
                picture_location: data.picture_location,
                describe: data.describe,
                price_usd: data.price_usd,
                quantity: data.quantity,
                maxover: data.maxover,
                prod_backlogged: data.prod_backlogged,
                backlogged: data.backlogged,
                gift_pending: data.gift_pending,
                prod_gift_pending: data.prod_gift_pending,
                manufacture_days: data.manufacture_days,
                prod_manufacture_days: data.prod_manufacture_days,
                limited_edition: data.limited_edition,
                add_quantity: data.add_quantity,
                manufactured_output: data.manufactured_output,
                buy_quantity: quantity,
            }
        };
            updateQuantityMax(
            data.add_quantity? (data.quantity-data.backlogged)+(data.maxover-data.prod_backlogged):
            (data.maxover-data.prod_backlogged)
            )
            // newArr[index]=parseInt(value)
            dispatch(uploadCartItem(newCheckedMerch))
    
        };
    // const handleMerchClick = (id) => {
    //     if (checkedIds.includes(id)) {
    //       setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    //     //   dispatch(uploadWishItem({"checkedMerch": checkedIds.filter((checkedId) => checkedId !== id)}))
    //     } else {
    //       setCheckedIds([...checkedIds, id]);
    //     //   dispatch(uploadWishItem({"checkedMerch": [...checkedIds, id]}))
    //     }
    //   };
    

    return (
        <Box>

            <div>
                {!loadingData ?            
                    <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                    >
                        <Grid item xs={12} sx={{border:`solid ${color}`, borderWidth: .5, borderRadius: 2}}>
                            <ProductSelector product_id={product_id} merch={merchs} group_product={group_product} color={color} getSelectedMerch={(value, attribute,old_merch,manufactured_output) => {getSelectedMerch(value, attribute,old_merch,manufactured_output)}} quantity={quantity}/>
                        </Grid>
                        {/* {accessories.length>0 ?
                            <Grid item xs={12} sx={{border:`solid ${color}`, borderWidth: .5, borderRadius: 2}}>   
                                <FormControl labelPlacement="start" sx={{width:'100%'}}>
                                    <Grid item xs={12} sx={{ pl:'2px'}}>
                                        <FormLabel 
                                            labelPlacement="start" 
                                            classes={classes}
                                            id="accessories-label"
                                            sx={{ pl:'2px', color:color }}
                                        >Accessories</FormLabel>
                                        
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl:'7px'}}>
                                    {
                                    accessories.map(
                                        (accessory,index)=>(
                                            <Grid item xs={12}>
                                        
                                                <MerchandiceCheckbox
                                                    key={accessory.id}
                                                    merch={accessory}
                                                    checked={checkedIds.includes(accessory.id)}
                                                    onMerchClick={() => handleMerchClick(accessory.id)}
                                                    label={{ inputProps: { 'aria-label': 'Checkbox' } }}

                                                />
                                            </Grid>
                                        )
                                        )
                                    }
                                    </Grid>
                                </FormControl>
                            </Grid>
                        :
                            <></>
                        } */}
                    </Grid>
                :
                <>
                loading...
                </>
            }
            </div>
        </Box>
    )
}


export default BuyProduct