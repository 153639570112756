import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Credit from './Credit'
import { Link } from 'react-router-dom'
import AddressInput from './AddressInput'
import SmallView from './SmallView'
import ShipSelections from './ShipSelections'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, Grid } from '@mui/material';
import apit from '../api/authApi';
import Confetti from '../badge/Confetti'
import { useAppSelector } from '../../app/hooks';
import QR from '../company/QR'
// import stripe;
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  badgeimage: {
    backgroundColor: '#282c34',
},
compborder: {
    backgroundColor: '#13271C',
    paddingTop: '10px',
    marginBottom: '10px',
    marginTop: '20px',
    borderRadius: "4px 4px 4px 4px",
},
})

const LoggedInHome=()=>{
    const [addresses, setAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState(false);
    const [openAddress, setOpenAddress] = useState(false);
    const [openTotal, setOpenTotal] = useState(false);
    const [openShipping, setOpenShipping] = useState(false);
    const [wishes, setWishes] = useState([]);
    // const [shippingOptions, setShippingOptions] = useState([]);
    const [openBuy, setOpenBuy] = useState(false);
    const [url, setUrl] = useState('');
    const [qrLocation, setQrLocation] = useState('');
    const [username, setUsername] = useState('');
    const [address, setAddress] = useState('');
    const [shipId, setShipId] = useState('');
    const [selectShipmentCost, setSelectShipmentCost] = useState(null);
    // const [rateId, setRateId] = useState('');
    // const [rateId, setRateId] = useState('');
    const [total, setTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    const [taxId, setTaxId] = useState(0);
    const [charge, setCharge] = useState(0);
    const [orderRecived, setOrderRecived] = useState(null);
    const classes = useStyles()
    const [adr, setAdr] = useState(null);

    useEffect(()=> {

        apit.get(`/api/order/address`).then(data => {
            setAddresses(data.data)
            if (data.data.length > 0) {
                setAddressSelected(true)
                setAdr(data.data[data.data.length-1].id)
                setAddress(data.data[data.data.length-1])
            }
            
        })

        apit.get(`/api/order/carts/checkout`).then(data => {
            setWishes(data.data)
        })

    },[]);


    const handleSubmit = () => {
        console.log('submit orders')
        setOpenBuy(true)
    }

    const showQR = (data) => {
        console.log(data.data)
        console.log('testing')
        setUrl(data.data.qr_url)
        setUsername(data.data.username)
        setQrLocation(data.data.qr_location)
        // setOpenBuy(true)
    }

    const handleClose = () => {   
        setOpenBuy(false)
    }

    const handleOrder = (id) => {   
        setOpenBuy(false)
        setOrderRecived(id)

    }

    const handleChangeAddress = (event) => {
        setAdr(event.target.value);
        setAddressSelected(true)
        setAddress(addresses.find(x => x.id === event.target.value))
      };

    const handleCloseAddress = () => {   
        setOpenAddress(false)
        setOpenShipping(false)
    }
    const getShippingOptions = () => { 
        try{

            const body = {address_id: address.id}
            apit.put(`/api/order/calcshipment`,body).then(data => {
                console.log(data.data.object_id)
                setShipId(data.data.object_id)
                setOpenShipping(true)
            })
            
        } catch (error) {
            console.error("Caught error:", error)
        }
    }
    const handleTotal = () => { 
        try{

            if (selectShipmentCost && address.id) {
                const body = {
                    address_id: address.id,
                    shipment_id: selectShipmentCost
                }
                console.log(body)
                apit.put(`/api/order/calctax`,body).then(data => {
                    setTaxId(data.data.tax_id)
                    setCartTotal(data.data.cart_total/100)
                    setShipping(data.data.shipping/100)
                    setTax(data.data.tax_amount/100)
                    setCharge(data.data.charge/100)
                    setTotal(data.data.total/100)
                    setOpenTotal(true)
                })
            }
        } catch(error) {
                console.error("Caught error:", error)
                }
    };

    const handleCloseTotal = () => {   
        setOpenTotal(false)
    }
    const handleChange = (value) => {
        setSelectShipmentCost(value)
    };
    const newAddress = (id) => {
        setAdr(id)
        setOpenAddress(false)
        setAddressSelected(true)
        apit.get(`/api/order/address`).then(data => {
            setAddresses(data.data)
            setAddress(data.data[data.data.length-1])
        })
    }


    return (
        <div>
            {wishes.length===0 ?
                <Paper elevation={5} className="App-paper-full">
                    <Link to='/cart' style={{ textDecoration: 'none' }}>
                        <Typography variant="body1" textAlign="center">No cart items selected</Typography>
                    </Link>
                </Paper>
            :
                <>
                    {orderRecived?

                        <Paper elevation={5} className="App-paper-full">
                            <Grid 
                                container
                                // direction="row-reverse"
                                justifycontent="center"
                                alignitems="center"
                            >
                                {/* <Grid item xs={12} justifycontent="center">
                                <Typography textAlign="center">Your order has been received: order id ({orderRecived})</Typography>
                                </Grid> */}
                                <Grid item xs={12} justifycontent="center" className={classes.compborder}>
                                    <Typography variant="h6" textAlign="center" color='white'>Congradulations!!</Typography>
                                    <Box sx={{ display: 'flex',
                                        width: '100%', margin: 'auto'}} className={classes.compborder} alignitems="center" justifyContent="center" display="flex">
                                    <Avatar 
                                        src={process.env.PUBLIC_URL + '/img/badges/influencer_light.png'}
                                        sx={{ width: 150, height: 150 }}
                                        className={classes.badgeimage}
                                    />
                                    </Box>
                                    <Typography variant="h6" textAlign="center" color='white'>Influencer Badge</Typography>
                                    <Grid item xs={12} justifycontent="center">

                                        <Box sx={{ display: 'flex',
                                            width: '100%', margin: 'auto'}} alignitems="center" justifyContent="center" display="flex">
                                            <QR username={username} location={qrLocation} url={url}/>
                                        </Box>
                                    </Grid>

                                </Grid>
                                <Confetti open={orderRecived? true:false}/>
                            </Grid>
                            
                        </Paper>
                    :
                        <Paper elevation={5} className="App-paper-full">
                            <Grid 
                                container
                                // direction="row-reverse"
                                justifycontent="center"
                                alignitems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h3" textAlign="center">Checkout</Typography>
                                </Grid>
                                {!openTotal ?
                                    <Grid xs={12}>
                                        {!openShipping ?
                                            <Grid 
                                                container
                                                // direction="row-reverse"
                                                justifycontent="center"
                                                alignitems="center"
                                            >
                                                <Grid item xs={12}>
                                            <Typography variant="h4" sx={{ pr: 5 }} textAlign="left">1. Choose a Shipping Address</Typography>
                                            </Grid>
                                            <br></br>
                                                {addressSelected? 
                                                    <Grid 
                                                    container
                                                    // direction="row-reverse"
                                                    justifycontent="center"
                                                    alignitems="center"
                                                    >
                                                        <Grid item xs={2} display="flex" alignItems="center">
                                                            <Button 
                                                                sx={{ mt: 1, mr: 1 }} 
                                                                type="submit" 
                                                                variant="contained" 
                                                                onClick={()=>{setOpenAddress(true)}}
                                                            >
                                                            Add
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={10} alignItems={"center"}>
                                                            <FormControl>
                                                                <FormLabel sx={{ width: '100%' }} id="influencer-label">Shipping Address</FormLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={adr}
                                                                    label="Address"
                                                                    onChange={handleChangeAddress}
                                                                    sx={{ 
                                                                        width: '100%' 
                                                                        }}
                                                                >
                                                                    {
                                                                        addresses.map(
                                                                            (address,index)=>(
                                                                                <MenuItem value={address.id}>{address.address1}</MenuItem>
                                                                            )
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        
                                                        </Grid>
                                                        <Grid item xs={12} md={12} mb='20px' mt='20px' alignItems={"left"}>
                                                            {address.name}
                                                            <br></br>
                                                            {address.address1} {address.address2},
                                                            <br></br>
                                                            {address.city}, {address.state} {address.zip}
                                                        </Grid>
                                                    </Grid>        
                                                :
                                                    <Grid item xs={6} alignItems={"center"}>
                                                        <Button 
                                                            sx={{ mt: 1, mr: 1,width: '100%' }} 
                                                            type="submit" 
                                                            variant="contained" 
                                                            onClick={()=>{setOpenAddress(true)}}
                                                        >
                                                            Add Address
                                                        </Button>
                                                    </Grid>
                                                }
                                                <Button 
                                                    sx={{ mt: 1, mr: 1,width: '100%' }} 
                                                    type="submit" 
                                                    variant="contained" 
                                                    onClick={()=>{getShippingOptions()}}
                                                    disabled={addressSelected? false : true }
                                                >
                                                    Shipping Options
                                                </Button>
                                            </Grid>     
                                            :
                                            <Grid 
                                            container
                                            // direction="row-reverse"
                                            justifycontent="center"
                                            alignitems="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button 
                                                    sx={{ mt: 1, mr: 1 }} 
                                                    type="submit" 
                                                    variant="contained" 
                                                    onClick={()=>{setOpenShipping(false)}}
                                                    disabled={addressSelected? false : true }
                                                >
                                                    Change Shipping Address
                                                </Button>
                                                <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Shipping Rates</FormLabel>
                                                    <Grid 
                                                        container
                                                        // direction="row-reverse"
                                                        justify="center"
                                                        alignitems="left"
                                                        sx={{border:'solid grey'}}
                                                    >
                                                        <ShipSelections 
                                                            ship_id={shipId}
                                                            setSelection={(value)=>{handleChange(value)}}
                                                        />
                                                    </Grid>
                                                </FormControl>
                                                </Grid>
                                                <Button 
                                                    sx={{ mt: 1, mr: 1,width: '100%' }} 
                                                    type="submit" 
                                                    variant="contained" 
                                                    onClick={handleTotal}
                                                    disabled={addressSelected && selectShipmentCost? false : true }
                                                >
                                                    Calculate Total
                                                </Button>
                                            </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                :
                                    <Grid 
                                        container
                                        // direction="row-reverse"
                                        justifycontent="center"
                                        alignitems="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button 
                                                sx={{ mt: 1, mr: 1 }} 
                                                type="submit" 
                                                variant="contained" 
                                                onClick={handleCloseTotal}
                                                disabled={addressSelected? false : true }
                                            >
                                                Change Shipping Address
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button 
                                                sx={{ mt: 1, mr: 1 }} 
                                                type="submit" 
                                                variant="contained" 
                                                onClick={handleCloseTotal}
                                                disabled={addressSelected? false : true }
                                            >
                                                Change Shipping Method
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} mb='20px'>
                                            <Typography variant="h4" textAlign="left">2. Review Product Total</Typography>
                                        </Grid>
                                        <Grid item xs={12} mb='20px'>
                                            {
                                                
                                                wishes.map(
                                                    (wish,index)=>(
                                                        <Grid item xs={12}>
                                                            <SmallView key={index}
                                                                id={wish.id}
                                                                company_view={wish.company_view}
                                                                product_view={wish.merchandice_name}
                                                                price={wish.total}
                                                                quantity={wish.cart_quantity}
                                                                picture={wish.picture_location}
                                                                name={wish.name}
                                                            />
                                                        </Grid>
                                                    )
                                                )
                                            }
                                            
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingLeft: '1em' }} style={{ display: 'inline-block' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize:'1.2rem'}} textAlign="left">Cart Total: ${cartTotal}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingLeft: '1em' }} style={{ display: 'inline-block' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize:'1.2rem'}} textAlign="left">Tax: ${tax}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingLeft: '1em' }} style={{ display: 'inline-block' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize:'1.2rem'}} textAlign="left">Shipping: ${shipping}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingLeft: '1em' }} style={{ display: 'inline-block' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize:'1.2rem'}} textAlign="left">Credit Charge: ${charge}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingLeft: '1em' }} style={{ display: 'inline-block' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize:'2rem'}} textAlign="left">Total: ${total}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button 
                                                sx={{ mt: 1, mr: 1,width: '100%' }} 
                                                type="submit" 
                                                variant="contained" 
                                                onClick={handleSubmit}
                                                disabled={addressSelected? false : true }
                                            >
                                                Enter Credit Info
                                            </Button>
                                        </Grid>

                                    </Grid>
                                }
                                <br></br>
                                
                                
                            </Grid>
                            <Dialog
                                open={openAddress}
                                onClose={handleCloseAddress}
                                aria-labelledby="delete-dialog-title"
                                aria-describedby="delete-dialog-description"
                                maxWidth='lg'
                                fullWidth='md'
                            >
                                <DialogTitle id="delete-dialog-title">
                                {"Enter New Billing Information"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="delete-dialog-description">
                                        <AddressInput close={()=>{handleCloseAddress()}} addedAddress={(id)=>{newAddress(id)}}/>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseAddress}>Cancel</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={openBuy}
                                onClose={handleClose}
                                aria-labelledby="delete-dialog-title"
                                aria-describedby="delete-dialog-description"
                                maxWidth='lg'
                                width='md'
                            >
                                <DialogTitle id="delete-dialog-title">
                                {"Credit Information"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="delete-dialog-description">
                                        <Credit close={(order_id)=>{handleOrder(order_id)}} address_id={address.id} tax_id={taxId} shipment_amount={selectShipmentCost} total={total} showQR={(data)=>showQR(data)}/>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                </DialogActions>
                            </Dialog>
                        </Paper>
                    }
                </> 
            }
        </div>
        )
    }

    const CheckoutPage = () => {
        const isAuth = useAppSelector((state) => state.loginstat.value)


        return (
            <div className="home container">
                {isAuth?
                    <LoggedInHome/>
                :
                    <Paper>
                        Please Log in to see this page
                    </Paper>
                }
            
        </div>
    )
}


export default CheckoutPage