import { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BuyProduct from './BuyProduct';
import ProductAddon from './ProductAddon';

import jwtCheck from '../../services/jwtCheck';
import { loginLoad } from '../../features/loading';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { uploadCartItem } from '../../features/cartlist';
import MerchandiceCheckbox from './MerchandiceCheckbox'
import { stakeholderLogin } from '../../features/login';
import apit from '../api/authApi';
import { fetchCompanyData } from '../../features/viewcompany';
import { followInfluencerSet } from '../../features/followinfluencer';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block",
        maxWidth: '120px'
        },
    labelPlacementStart: {
        justifyContent: 'space-between'
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogolg: {
        color: '#9BCDB0',
        maxWidth: '230px',
        marginRight: '.5em',
        maxHeight: '250px',
      },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
    root: {
        // component default is "inline-flex", using "flex" makes the
        // label + control group use the entire width of the parent element
        display: "flex",
        // component default is "flex-start", using "space-between" pushes
        // both flexed content to the right and left edges of the flexbox
        // Note: the content is aligned to the right by default because
        // the 'labelPlacement="start"' component prop changes the flexbox
        // direction to "row-reverse"
        justifyContent: "space-between",
    },
})


const ProductBuyPage = ({viewManuf}) => {
    const [loadingData, setLoadingData] = useState(true);                                              
    const viewProduct  = useAppSelector((state) => state.viewproduct)
    const [price,setPrice]=useState(null);
    // const [wishes, setWishes] = useState([]);
    const isAuth = useAppSelector((state) => state.loginstat.value)
    const showCompany  = useAppSelector((state) => state.viewcompany)
    const [quantity, setQuantity] = useState(1)
    const [newInfluencer, setNewInfluencer] = useState({"username": "DEFAULT"})
    const [influencerError, setInfluencerError] = useState(false)
    const [influencerExistsError, setInfluencerExistsError] = useState(false)
    const [quantityError, setQuantityError] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)
    const [quantityMax, setQuantityMax] = useState(1)
    const follow = useAppSelector((state) => state.followinfluencer)
    const wishes = useAppSelector((state) => state.cartlist)
    const dispatch = useAppDispatch()
    

    const classes = useStyles()

    useEffect(()=> {
        setLoadingData(false)
        setPrice(Math.max(...Object.values(wishes).map(item => item.price_usd))*quantity)

      },[]);
      
    useEffect(()=> {
        setPrice(Math.max(...Object.values(wishes).map(item => item.price_usd))*quantity)

    },[wishes]);

    const handleClose = () => {
        setOpenLogin(false);
    };

    const updateWishes = async (merch_list) => {
        
        try {
            // const response = await api.get(`/api/order/merchandice/${product_id}`).then(data => {
            //     // setViewProduct(data.data)
            //     })
            //     .catch(error => console.log(error));
            console.log(merch_list)
            console.log(typeof(merch_list))
        } catch (err) {
            console.log(err)
        }
    }

      const updateQuantity = (event) => {
        const maxI = Math.min(...Object.values(wishes).map(item => (
            item.add_quantity? ((item.quantity-item.backlogged)+(item.maxover-item.prod_backlogged)) :
            (item.maxover-item.prod_backlogged)
          )))
        // const maxI = Math.max(radioMerch.quantity)
        setQuantityMax(maxI)
        
        
        if (event.target.value > 0 || event.target.value == '') {
            if (event.target.value >= maxI) {
                setQuantity(maxI)
                viewManuf(true, maxI)
                setPrice(Math.max(...Object.values(wishes).map(item => item.price_usd))*maxI)

            } else {
                setQuantity(event.target.value)
                if (event.target.value<maxI) {
                    viewManuf(false, maxI)
                } else {
                    viewManuf(true, maxI)
                }
                setPrice(Math.max(...Object.values(wishes).map(item => item.price_usd))*event.target.value)

            }

        }
      };

      const userLogin =  async  (action) => {
        // this sets isLoading to the delay does not confonfuse . intended for a loading icon
        dispatch(loginLoad())
        console.log(action + " Pressed, updating login")
    
        if (action=='Login') {
          // this will log in user
          if (!jwtCheck.isLoggedIn()) {
            await jwtCheck.doLogin()
          }
          console.log('-----------wanted to login GUI---------------')
          // console.log('Wanted to be logedin')
          //This sets global is auth value
          dispatch(stakeholderLogin())
          // logout({ redirectUri: 'http://localhost:3000/' })
        } else {
          console.log('Weird action {action}')
        }
      };

      const changeInfluencer = () => {
        dispatch(followInfluencerSet(newInfluencer))
        setInfluencerExistsError(false)
        setInfluencerError(false)
    };
    const updateMax = (value) => {
        const maxValue=Math.min(value,quantityMax)
        if (quantityMax > maxValue) {
            setQuantityMax(maxValue)
            if (quantity>maxValue) {
                setQuantity(maxValue)
                viewManuf(true, maxValue)
            }
        }
    };

      const handleSubmit = (event) => {

        if (isAuth) {
            const body = {
                product_id: viewProduct.id,
                cart: wishes,
                influencer: follow,
                quantity: quantity
            }
            console.log(quantity)
            apit.post('/api/order/cart',body).then(data => {
                console.log(data.data.msg)
                dispatch(uploadCartItem({}));
                dispatch(fetchCompanyData(0))
                window.location.href = '/cart';
            }).catch(error => {
                // Check if the error response exists
                if (error.response) {
                    const { status, data } = error.response;
                    if (error.response.status === 423) {
                        setInfluencerError(true); // Set influencer error state
                        setNewInfluencer(data)
                    } else if (error.response.status === 425) {
                        setInfluencerExistsError(true); // Set influencer error state
                        setNewInfluencer(data)
                    } else if (error.response.status === 424) {
                        setQuantityError(true); // Set quantity error state
                        setQuantity(data.quantity)
                        setQuantityMax(data.quantity)
                        viewManuf(true, data.quantity)
                    }
                } else {
                    console.error("An unknown error occurred:", error);
                }
            });
        } else {
            // setShowDanger(true)
            // setShow(false)
            // userLogin('Login')
            setOpenLogin(true)
        }
        event.preventDefault()
    }

    return (
        <Box> 
            <Paper elevation={5} className="App-paper-full">
                
                <form>
                    {!loadingData ?            
                        <Grid 
                            container
                            // direction="row-reverse"
                            justify="center"
                            alignitems="center"
                        >
                            <Grid item xs={12} sx={{padding: 0, margin: 0}}>
                                <BuyProduct  
                                    product_id={viewProduct.id}
                                    updateMerch = {(merch_list) => {updateWishes(merch_list)}}
                                    color={showCompany.secondary_color}
                                    quantity={quantity}
                                    updateQuantityMax={(value)=>updateMax(value)}
                                />
                                <ProductAddon updateQuantityMax={(value)=>updateMax(value)}/>
                            </Grid>
                        </Grid>
                    :
                    <>
                    loading...
                    </>
                }
                </form>
            </Paper>
            <Paper elevation={5} className="App-paper-full">
                <Grid item xs={12}>
                    <FormLabel id="radio-gift">Final Product</FormLabel>
                </Grid>
                {Object.values(wishes).length>0 ?
                    <Grid item xs={12} display="inline-block" sx={{width: '100%'}} alignItems={"center"}>
                    <Grid item xs={12} display="flex" sx={{width: '100%'}} alignItems={"center"}>
                        <Grid item xs={12} sx={{border:`solid ${showCompany.color}`, borderWidth: .5, borderRadius: 2}}>   
                            {
                            Object.values(wishes).map(
                                (wish,index)=>(
                                    wish ? (<Grid key={wish.id} item xs={12}>

                                        <MerchandiceCheckbox
                                            key={wish.id}
                                            merch={wish}
                                            checkout={true}
                                            viewCheck={false}
                                            onMerchClick={()=>{}}
                                            label={{ inputProps: { 'aria-label': 'Checkbox' } }}
                                            color={showCompany.color}
                                            quantity={quantity}
                                        />
                                    </Grid>  ):null 
                                )
                                )
                            }
                        </Grid>
                    </Grid>
                    {quantityMax>1 ?
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ height: '.75rem', mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                    Maximum Quantity: {quantityMax}
                                </Typography>
                            </Grid>
                        :
                            <Grid item xs={12}>
                            </Grid>
                    }
                    <Grid 
                        item 
                        xs={ 12} 
                        sx={{
                            alignSelf: 'center', 
                            border:`solid ${showCompany.color}`, 
                            borderWidth: .5, 
                            borderRadius: 2,
                            display: 'flex', 
                            alignItems: 'center',
                            padding: '8px'
                            }}
                        >
                            <Typography 
                            sx={{ 
                                pl: '2px', 
                                color: showCompany.color, 
                                marginRight: '8px' // Space between label and input
                            }}
                            id="quantity-label"
                        >
                            Quantity
                        </Typography>
                        {/* <FormControl> */}
                            {/* <FormLabel 
                                labelPlacement="end" 
                                id="quantity-label" 
                                sx={{ pl:'2px', color: showCompany.color }}
                            >
                                Quantity
                            </FormLabel> */}
                            <TextField
                                type="number"
                                className={classes.field}
                                variant="outlined"
                                name="quantity"
                                defaultValue="1"
                                maxvalue={quantityMax}
                                value={quantity}
                                // show={false}
                                onChange={updateQuantity}
                            />
                        {/* </FormControl> */}
                    </Grid>
                
                    <Grid item xs={12}>
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" onClick={handleSubmit}>
                            Add to Cart: ${price}
                        </Button>
                        </Grid>
                    </Grid>
                :
                    <Typography variant="body2" sx={{ mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>Please Select Merchandice Above to place an order</Typography>
                }   
                
            </Paper>
            <Dialog
                open={openLogin}
                onClose={handleClose}
                aria-labelledby="login-dialog-title"
                aria-describedby="login-dialog-description"
            >
                <DialogTitle id="login-dialog-title">
                {"Must be logged in"}
                </DialogTitle>
                <DialogContent>
                    Please log in to add to your
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=>{userLogin('Login')}}>
                    Log In
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={influencerError}
                onClose={handleClose}
                aria-labelledby="login-dialog-title"
                aria-describedby="login-dialog-description"
            >
                <DialogTitle id="login-dialog-title">
                {"Influencer Error"}
                </DialogTitle>
                <DialogContent>
                    "{follow.username.toUpperCase()}" does not influence this product. Can "{newInfluencer.username.toUpperCase()}" influence you for this sale?
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setInfluencerError(false)}}>Cancel</Button>
                <Button onClick={()=>{changeInfluencer()}}>
                    Change Influencer
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={influencerExistsError}
                onClose={handleClose}
                aria-labelledby="login-dialog-title"
                aria-describedby="login-dialog-description"
            >
                <DialogTitle id="login-dialog-title">
                {"Influencer Error"}
                </DialogTitle>
                <DialogContent>
                    "{newInfluencer.username.toUpperCase()}" is already influencing you for this company.  Therefore, "{follow.username.toUpperCase()}" can not be your influencer.  Can we switch the influencer to "{newInfluencer.username.toUpperCase()}"?
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setInfluencerExistsError(false)}}>Cancel</Button>
                <Button onClick={()=>{changeInfluencer()}}>
                    Change Influencer
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={quantityError}
                onClose={handleClose}
                aria-labelledby="login-dialog-title"
                aria-describedby="login-dialog-description"
            >
                <DialogTitle id="login-dialog-title">
                {"Quantity Error"}
                </DialogTitle>
                <DialogContent>
                    The maximum Quantity this product can manufacture currently is {quantityMax}
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setQuantityError(false)}}>Cancel</Button>
                <Button onClick={()=>{handleSubmit()}}>
                    Add {quantityMax} to cart
                </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}


export default ProductBuyPage;