import { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProductBuyPage from './ProductBuyPage';

import { fetchProductData } from '../../features/viewproduct';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import api from '../api/companies';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block",
        maxWidth: '120px'
        },
    labelPlacementStart: {
        justifyContent: 'space-between'
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogolg: {
        color: '#9BCDB0',
        maxWidth: '230px',
        marginRight: '.5em',
        maxHeight: '250px',
      },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
    root: {
        // component default is "inline-flex", using "flex" makes the
        // label + control group use the entire width of the parent element
        display: "flex",
        // component default is "flex-start", using "space-between" pushes
        // both flexed content to the right and left edges of the flexbox
        // Note: the content is aligned to the right by default because
        // the 'labelPlacement="start"' component prop changes the flexbox
        // direction to "row-reverse"
        justifyContent: "space-between",
    },
})

const label = { inputProps: { 'aria-label': 'Checkboxgift' } };

const ProductPage = () => {
    const [loadingData, setLoadingData] = useState(true);                                              
    const [manufacturingInfo, setManufacturingInfo] = useState(false);   
    const [maxValue, setMaxValue] = useState(1);                                            
    const viewProduct  = useAppSelector((state) => state.viewproduct)
    const showCompany  = useAppSelector((state) => state.viewcompany)
    const followUsername = useAppSelector((state) => state.followinfluencer.username)
    

    const classes = useStyles()

    useEffect(()=> {
        setLoadingData(false)

      },[]);

    const changeManufactureInfo = (value,maxvalue) => {
            setManufacturingInfo(value)
            setMaxValue(maxvalue)
    };


    return (
        <Box> 
            <Paper className="App-paper-full">
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="subtitle">Influencer: {followUsername}</Typography>  
                </Grid>
            </Paper>
            <Paper elevation={5} className="App-paper-full">
                
                <form>
                    {!loadingData ?            
                        <Grid 
                            container
                            // direction="row-reverse"
                            justify="center"
                            alignitems="center"
                        >
                            <Grid item xs={12} sx={{border:`solid ${showCompany.primary_color}`, borderWidth: .5, borderRadius: 2}}>
                                {viewProduct.name_view}
                            </Grid>
                            <Grid item xs={12} sx={{padding: 0, margin: 0}}>
                                <img src={process.env.PUBLIC_URL + '/img' + viewProduct.picture_location} className={classes.navlogolg} alt="Logo" />
                            </Grid>
                            <Grid item xs={12} sx={{padding: 0, margin: 0, borderRadius: 2, backgroundColor: viewProduct.backlogged>0 || manufacturingInfo ? "rgba(255, 165, 0, 0.5)": "transparent"}}>
                            
                            <Typography variant="body1" sx={{ mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                {/* <InfoOutlinedIcon/> */}
                                Production Phase: {viewProduct.production_phase}
                            </Typography>
                            {viewProduct.backlogged>0 || manufacturingInfo ?
                                    <div>
                                        { viewProduct.minover>0 ?
                                            <Typography variant="body2" sx={{ height: '.75rem', mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                                {viewProduct.backlogged}/{viewProduct.minover} {viewProduct.backlogged/viewProduct.minover}% funded
                                            </Typography>
                                        :   
                                            <div>
                                                { viewProduct.maxover>0 ?
                                                    <div>
                                                        <Typography variant="body2" sx={{ height: '.75rem', mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                                           This product is ready to manufacture more products ASAP
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ height: '.75rem', mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                                            Estimated Manufacturing delay: {viewProduct.manufacture_days} days
                                                        </Typography>
                                                        
                                                        
                                                    </div>
                                                :
                                                    <div></div>
                                                }
                                            </div> 
                                        }
                                        <Typography variant="body2" sx={{ mt: '10px',width: '100%', flexShrink: 0, textAlign: 'center', alignSelf: 'center' }}>
                                            Backlog Manufacturing Capacity: {maxValue} units
                                        </Typography>
                                    </div>
                                :
                                    <div>
                                    </div>
                            }

                            </Grid>

                        </Grid>
                    :
                    <>
                    loading...
                    </>
                }
                </form>
            </Paper>
            <ProductBuyPage viewManuf={(value, maxvalue)=>{changeManufactureInfo(value, maxvalue)}}/>
        </Box>
    )
}


export default ProductPage;