import React, { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import jwtCheck from '../services/jwtCheck';
import { stakeholderLogin, stakeholderLogout } from '../features/login';
import { loginLoad } from '../features/loading';

import GlowButton from './bar/GlowButton'


const MissionPage = () => {
    const logged = useAppSelector((state) => state.loginstat.value)
    const dispatch = useAppDispatch()

    const requestLogin =  async  () => {
        console.log('made it to login React')
        dispatch(loginLoad())
        if (!jwtCheck.isLoggedIn()) {
        await jwtCheck.doLogin()
        }
        console.log('-----------wanted to login GUI---------------')
        // console.log('Wanted to be logedin')
        //This sets global is auth value
        dispatch(stakeholderLogin())
        // logout({ redirectUri: 'http://localhost:3000/' })
        // setIsLoggedIn(UserService.isLoggedIn());
        // code={`/new/company`}
        window.location.href = '/new/company'
      };

    return (
        <Grid 
            justifycontent="center"
            alignitems="center"
        >
            <Grid item xs={12}>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Business Opportunity
                    </Typography>
                    <Typography align="left" variant="body1">
                        Adding your business to Brackett Innovations is a straightforward and cost-free process. To ensure alignment with our platform standards, 
                        please adhere to the following requirements:
                        <ul>
                            <li>
                                Maintain consistent pricing: The price of each product on Brackett Innovations should mirror that listed on your official business website.
                            </li>
                            <li>
                                Define a finders fee: To incentivize influencers and encourage successful sales, your business should establish a clear and defined finders fee.
                            </li>
                        </ul>
                    </Typography>
                    
                    {logged?
                        <GlowButton code={`/new/company`} name={'Add a business'}/>
                        :
                        <Button
                            // disabled=false
                            variant="contained"
                            onClick={() => {requestLogin()}}
                            sx={{ 
                                my: .5, 
                                color: '#E3F1E9', 
                                display: 'block',
                                marginRight: 'auto',
                                marginLeft: 'auto', 
                                boxShadow: `-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0`,
                                '&:hover': {
                                boxShadow: `-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9`,
                                backgroundColor: 'black',
                                },
                            }}
                            >
                            Login to Add Business
                            </Button>
                        // <Button onClick={() => {requestLogin();console.log('help')}} name={'Login to Add Business'}/>
                    }
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Influencer Opportunities
                    </Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            Welcome to Brackett Innovations – where anyone can become an influencer without needing a large following, hours of 
                            sponsorship hunting, or promoting products you don’t believe in. Every purchase is an opportunity to influence, and 
                            our platform rewards you for it. 
                        </p>
                        <p>
                            Our unique model empowers you to earn not only from products you endorse but also from those influenced by your 
                            recommendations. It’s designed like an ideal pyramid structure, allowing your impact to grow with every connection 
                            you make.
                        </p>
                        <p>
                            Brackett Innovations is your stage to build your brand and share products you truly stand behind. Your influence 
                            matters, shaping markets and elevating quality. Join us to turn your voice into an empire and your choices into 
                            meaningful change.
                        </p>
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Mission
                    </Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            At Brackett Innovations, our mission is to minimize the portion of funds spent on this platform that ends up with large 
                            corporations. Instead, we aim to return these funds to everyday Americans—individuals who genuinely deserve the compensation—while 
                            striving to revive the essence of the American dream.
                        </p>
                        <p>
                            To achieve this, we are committed to discontinuing sponsorships of large corporations for product marketing through ads. These 
                            traditional methods often prioritize companies with the largest advertising budgets rather than promoting the best products or 
                            businesses. Instead, our marketing strategy focuses on leveraging the internet to create an enhanced "word of mouth" effect, 
                            encouraging small influencers to showcase products authentically.
                        </p>
                        <p>
                            We are proud to have established a strong foundation with a robust system of continuous development and integration. This 
                            allows us to take feedback from the users while rolling out daily updates, ensuring the platform's capabilities evolve and 
                            improve constantly based on customers needs.
                        </p>
                        <p>
                            Our initial launch spearheads a revolutionary e-commerce platform designed to empower and support the growth of small 
                            businesses. Our goal is to ease the burden of startup costs by providing a free platform for showcasing and promoting small 
                            enterprises.
                        </p>
                        <p>
                            Future capabilities will leverage innovative strategies to provide businesses with unprecedented opportunities to connect 
                            with a wide network of individuals and resources. This will empower them to excel in marketing, creation, and the development 
                            of groundbreaking products. Join us on this journey, where the possibilities for success are limitless.
                        </p>
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Contact Us
                    </Typography>
                    <Typography align="left" variant="body1">
                        We welcome you to reach out to us for any inquiries, feedback, or concerns. Your insights are valuable to us, 
                        and we appreciate your feedback. We will make every effort to respond to your communication promptly
                    </Typography>
                    <Typography align="left" variant="body1">
                        Email: brackettinnovations.feedback@gmail.com
                    </Typography>
                </Paper>
            </Grid>

        </Grid>  
    )
}


export default MissionPage