// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../components/api/companies';
import { useAppSelector } from '../app/hooks';


export const fetchUserData = createAsyncThunk(
  'followinfluencer/fetchUserData',
  async (influencer_id, { getState }) => {
    // const influencer_id = localStorage.getItem('Influencer') || '1';
    // const influencer_id = useAppSelector((state) => state.followInfluencer.id)
    const response = await api.get(`/api/stakeholder/influencer/${influencer_id}`);
    const data = response.data;
    // return response.data;

    const currentState = getState().viewproduct;
    console.log(currentState)
    const newState = { ...currentState, ...data };
    localStorage.setItem('Influencer', JSON.stringify(newState));
  }
);


const initialState = {
  "id": 1,
  "user_entity_id": "1e37a207-29ea-497f-892c-7460c1e0d48d",
  "username": "bracketteng",
  "featured_company": 1,
  "views": 0,
  "picture_location": "/default.jpg"
};

const followinfluencerSlice = createSlice({
  name: 'followinfluencer',
  initialState,
  reducers: {
    followInfluencer(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      return action.payload;
    },
    followInfluencerSet(state, prod) {
      return prod.payload;
    }
    // decrement
    // reset
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        return { ...state, ...action.payload };
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { followInfluencer, followInfluencerSet } = followinfluencerSlice.actions;
export default followinfluencerSlice.reducer;