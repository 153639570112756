import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'
import { useEffect, useState } from 'react'
import apit from '../api/authApi';

import MediaInput from './MediaInput'
import GiftMediaInput from './GiftMediaInput'
import WishMerchandice from './WishMerchandice'

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles'

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    gifticon: {
        backgroundColor: '#ffc0cb',
        width: '2rem',
        height: '2rem',
        padding: '.3rem',
        objectFit: 'contains'
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const GiftSwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 42,
    padding: 10,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#ccc',
        transform: 'translateX(35px)',
        '& .MuiSwitch-thumb:before': {

          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/gifticon.png"
        })`,
          backgroundSize: '75%',
        },
        '& + .MuiSwitch-track': {
          opacity: 10,
          backgroundColor: '#9f2b68',
          ...theme.applyStyles('dark', {
            backgroundColor: '#9f2b68',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#ffc0cb',
      width: 39,
      height: 39,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/gifticon.png"
        })`,
        backgroundSize: '75%'
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#aab4be',
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#aab4be',
      borderRadius: 20 / 2,
      ...theme.applyStyles('dark', {
        backgroundColor: '#8796A5',
      }),
    },
  }));


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Wish = ({key,show_order,getCarts}) => {
    const [ showAddon, setShowAddon ] = useState(false);
    const [ addons, setAddons ] = useState([]);
    const [showDelete,setShowDelete]=useState(false)
    const [order,setOrder]=useState(show_order)
    const [showMedia,setShowMedia]=useState(false)
    const [showGiftMedia,setShowGiftMedia]=useState(false)
    const [showMediaNeeded,setShowMediaNeeded]=useState(false)
    const [editAddonId,setEditAddonId]=useState(0)
    const [response,setResponse]=useState(false)
    const [error,setError]=useState(false)
    const [serverResponse,setServerResponse]=useState('Processing...')
    const [message,setMessage]=useState("Wlellcome");
    const classes = useStyles()

    const changeClicked = () =>{
        console.log(order.max_quantity)
        if ((order.addon_media==1 && order.complete && order.cart_quantity<=order.max_quantity && (order.gift? order.gift_media: true)) || order.checked) {
            apit.get(`/api/order/cart/${order.id}/clicked`).then(data => {
                setOrder(data.data)
            }).catch(error => {
                // Check if the error response exists
                if (error.response) {
                    const { status, data } = error.response;
                    if (error.response.status === 423) {
                        setError(true); // Set influencer error state
                        setResponse(`Update Quantity, Max Quantity is ${data.quantity}`)
                    } 
                } else {
                    console.error("An unknown error occurred:", error);
                }
            });
        } else if (!order.checked) {
            setShowMediaNeeded(true)
        }
    }

    const changeGift = () =>{
        apit.get(`/api/order/cart/${order.id}/gift`).then(data => {
            setOrder(data.data)
        }).catch(error => {
            // Check if the error response exists
            if (error.response) {
                const { status, data } = error.response;
                if (error.response.status === 422) {
                    setError(true); // Set influencer error state
                    setResponse(data.msg)
                } 
            } else {
                console.error("An unknown error occurred:", error);
            }
        });

    }


    const addonClicked = () =>{
        console.log(`clicked id ${String(order.id)}`)
        // apit.get(`/api/order/${order.id}/cart/checked`).then(data => {
        //     getCarts()
        // })
        
        if (!showAddon) {
            apit.get(`/api/order/cart/${order.id}/addons`).then(data => {
                setAddons(data.data)
            })
        }
        setShowAddon(!showAddon)
    }

    const handleClose = () => {
        setShowDelete(false);
      };
    
      const handleCloseMedia = () => {
        apit.put(`/api/order/cart/mediacheck/${order.id}`).then(data => {
            setShowMedia(false);
            setOrder(data.data)
        })
        
      };
      const handleCloseGiftMedia = () => {
        apit.put(`/api/order/cart/mediacheck/${order.id}`).then(data => {
            setShowGiftMedia(false);
            setOrder(data.data)
        })
      };
      const handleCloseError = () => {
        setError(false);
      };
      const handleCloseMediaNeeded = () => {
        setShowMediaNeeded(false);
      };
      const GetCart = () => {
        getCarts()
      };
    
    const deleteWish = async () =>{
        console.log(order.id)
        const response = await apit.delete(`/api/order/cart/${order.id}/update`).then(data => {
            getCarts()
        })
        setShowDelete(false)
    }
    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
            sx={{border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
        >
            <Grid item xs={12} md={10}  display="flex" alignItems={"center"}>
                {/* <Box>
                    <Checkbox {...label} onClick={changeClicked} defaultChecked={checked? true: false}/>
                </Box> */}
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                    sx={{border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
                >
                    <Grid item xs={12}>
                        <WishMerchandice 
                            id={order.id}
                            name={order.name}
                            picture={order.picture_location}
                            manufacture_phase={order.manufacture_phase}
                            merch_quantity={order.merch_quantity}
                            total={order.total*order.cart_quantity}
                            description={order.describe}
                            cart_quantity={order.cart_quantity}
                            merch_manufacture_days={order.merch_manufacture_days}
                            prod_manufacture_days={order.prod_manufacture_days}
                            merch_backlogged={order.backlogged}
                            prod_backlogged={order.prod_backlogged}
                            merch_gift_pending={order.merch_gift_pending}
                            prod_gift_pending={order.prod_gift_pending}
                            backorder_delay_days={order.backorder_delay_days}
                            influencer={order.username}
                            checked={order.checked}
                            company_code={order.company_view}
                            title={order.name_view}
                            company={order.company_name}
                            primary_color={order.primary_color}
                            max_quantity={order.max_quantity}
                            changeClick={(value) => {changeClicked()}}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{borderRadius: 2,backgroundColor: "rgba(225, 225, 0, 0.25)"}}>
                        <Typography textAlign="center" variant="h4">Total: ${order.total*order.cart_quantity}</Typography>
                    </Grid>
                    <Grid item xs={5} md={4} >
                    <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                        >
                            <Grid item xs={12} >
                            {/* <Button size="small" variant="outlined" sx={{ minWidth: 'auto', p: '.25rem', pr: '.5rem', pl: '.5rem', m: 0, mr: '.25rem'}}>+</Button> */}
                                <Typography textAlign="center" variant="h6">Qty: {order.cart_quantity}</Typography>
                                {/* <Button size="small" variant="outlined" sx={{ minWidth: 'auto', p: '.25rem', pr: '.5rem', pl: '.5rem', m: 0, ml: '.25rem'}}>-</Button> */}
                            </Grid>
                            <Grid item xs={12} >
                                <Typography textAlign="center" variant="body2">Max: {order.cart_max_quantity}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} md={8} sx={{display: 'flex', borderRadius: 2,backgroundColor: "rgba(0, 128, 255, 0.25)",border: '1px dotted grey'}}>
                        <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"

                        sx={{width: '100%', border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
                        >
                            <Grid item xs={12} md={12} sx={{width: '100%',display: 'flex'}} alignItems={"center"}>
                            <FormControlLabel
                                control={<GiftSwitch  checked={order.gift} />}
                                label={ order.gift ? "" : "Gift"}
                                sx={{ml: '.5rem', mr: '.5rem'}}
                                onClick={()=>(changeGift())}
                            />
                                { order.gift ?
                                    <Button
                                        key={key}
                                        variant={order.gift_media? "outlined":"contained"}
                                        onClick={()=>{setShowGiftMedia(true)}}
                                        // onClick={deleteWish}
                                        sx={{ 
                                        m: .25, 
                                        p: .5, 
                                        display: 'block',
                                        marginRight: 'auto',
                                        minWidth: 'auto', 
                                        marginLeft: 'auto',    
                                        }}
                                        color={order.gift_media? "success":"error"}
                                    >
                                        Gift Media
                                    </Button>
                                :
                                    <Box sx={{display: 'flex'}}>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                    {/* <Grid item xs={10} md={12} sx={{display: 'flex', borderRadius: 2,backgroundColor: "rgba(0, 128, 255, 0.25)",border: '1px dotted grey'}} alignItems={"center"}>
                            {/* <Switch
                                key={key}
                                checked={true}
                                onChange={handleChange}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                            > */}
                                {/* Make Gift
                            </Switch> 
                            <FormControlLabel
                                control={<GiftSwitch  checked={order.gift} />}
                                label={ order.gift ? "" : "Gift"}
                            />
                        </Grid>
                    </Grid> */}
                {/* </Grid> */}
            </Grid>
    
            {/* <Grid item xs={2} md={12} ></Grid> */}
                {/* <Grid item xs={12}> happy </Grid> */}

            <Grid item xs={12} md={2} alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                >
                    <Grid item xs={6} md={12} sx={{display: { xs: 'none', md: 'flex' }}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={()=>{setShowDelete(true)}}
                            // onClick={deleteWish}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            width: '90%', 
                            marginLeft: 'auto',    
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>
                    {/* <Grid item xs={6} md={12} sx={{display: 'none'}} alignItems={"center"}>
                        <Link to={'/company/' + order.company_view + '/order/' + order.id} style={{ textDecoration: 'none' }}>
                            <Button
                                key={key}
                                variant="outlined"
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                width: '90%',
                                }}
                            >
                                Edit
                            </Button>
                        </Link>
                    </Grid> */}
                    <Grid item xs={6} md={12} sx={{display: 'block'}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant={order.complete? "outlined":"contained"}
                            onClick={()=>{setEditAddonId(0);setShowMedia(true)}}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            width: '90%',
                            }}
                            color={order.complete? "success":"error"}
                        >
                                    {order.complete ?
                                        <>Media</>
                                        :
                                        <>
                                            Add Media
                                        </>
                                    }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={12} sx={{display: { xs: 'block', md: 'none' }}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={()=>{setShowDelete(true)}}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            width: '90%', 
                            marginLeft: 'auto',    
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>  
                    {order.addons==0?
                        <></>
                    :
                        <Grid item xs={6} md={12} alignItems={"center"}>
                            <Button
                                key={key}
                                variant={order.addon_media? "outlined":"contained"}
                                // onClick={deleteWish}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                                color={order.addon_media? "success":"error"}
                                onClick={()=>(addonClicked())}

                            >
                                Addon
                            </Button>
                        </Grid>
                    }
                             
                </Grid>
            </Grid>
            {showAddon?
                    <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                    sx={{border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
                >
                    
                    {
                        addons.map(
                            (add_merch,index)=>(
                                <Grid 
                                container
                                // direction="row-reverse"
                                justify="center"
                                alignitems="center"
                                sx={{zIndex: 20, border: `solid ${order.primary_color}`,backgroundColor: `${order.primary_color}80`, borderWidth: .7, borderRadius: 2}}
                            >
                            <Grid item xs={12} md={10} sx={{ paddingRight: '.5em' }} display="flex" alignItems={"center"}>
                                <Grid item xs={12}>
                                    <WishMerchandice 
                                        id={add_merch.id}
                                        addon={true}
                                        picture={add_merch.picture_location}
                                        manufacture_phase={order.manufacture_phase}
                                        merch_quantity={order.merch_quantity}
                                        description={add_merch.describe}
                                        quantity={add_merch.cart_quantity}
                                        merch_manufacture_days={add_merch.merch_manufacture_days}
                                        prod_manufacture_days={add_merch.prod_manufacture_days}
                                        merch_backlogged={add_merch.backlogged}
                                        merch_gift_pending={add_merch.merch_gift_pending}
                                        prod_gift_pending={add_merch.prod_gift_pending}
                                        prod_backlogged={add_merch.prod_backlogged}
                                        max_quantity={add_merch.max_quantity}
                                        checked={order.checked}
                                        title={add_merch.name_view}
                                        total={add_merch.price_usd*order.cart_quantity}
                                        primary_color={add_merch.primary_color}
                                        changeClick={()=>(console.log('nothing'))}
                                    />
                                </Grid>

                            </Grid>
                             <Grid item xs={12} md={2} sx={{display: 'block'}} alignItems={"center"}>
                                {add_merch.media_id!=1?
                                <Button
                                    key={key}
                                    variant={add_merch.complete || order.addon_media? "outlined":"contained"}
                                    onClick={()=>{setEditAddonId(add_merch.id);setShowMedia(true)}}
                                    sx={{ 
                                    my: .5, 
                                    display: 'block',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    width: '90%',
                                    }}
                                    color={add_merch.complete || order.addon_media? "success":"error"}
                                >
                                        {add_merch.complete ?
                                            <>Media</>
                                            :
                                            <>
                                                Add Media
                                            </>
                                        }
                                
                                    </Button>
                                :<></>}    
                            </Grid>
                                </Grid>
                                )
                        )
                    }
                    
                   
                </Grid>
            :
                <></>
            }

            <br></br>
            <Dialog
                open={showDelete}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                {"Delete Wish"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Do you want to delete this wish?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Link to='/cart' style={{ textDecoration: 'none' }}>
                    <Button onClick={deleteWish} autoFocus>
                        Delete
                    </Button>
                </Link>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showMediaNeeded}
                onClose={handleCloseMediaNeeded}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Media Required"}
                </DialogTitle>
                <DialogContent>
                    Missing Required Media or Quantity is out of manufacturing capabilities. Please select the red Media button(s) to add the required Media for this checkout or adjust Quantity.
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseMediaNeeded}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showMedia}
                onClose={handleCloseMedia}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Update Media"}
                </DialogTitle>
                <DialogContent>
                    <MediaInput cartaddon_id={editAddonId} cart_id={order.id} color={order.primary_color} updateCarts={()=>{GetCart()}}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseMedia}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showGiftMedia}
                onClose={handleCloseGiftMedia}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Gift Media"}
                </DialogTitle>
                <DialogContent>
                    <GiftMediaInput quantity={order.cart_quantity} cart_id={order.id} color={order.primary_color} updateCarts={()=>{GetCart()}}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseGiftMedia}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={error}
                onClose={handleCloseError}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Update Media"}
                </DialogTitle>
                <DialogContent>
                    {response}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseError}>Close</Button>
                </DialogActions>
            </Dialog>

        </Grid>

    )
}


export default Wish