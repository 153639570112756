import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '40px',
        marginRight: '.5em',
    },
})

const SmallView = ({id,picture, name, company_view,product_view,quantity, price}) => {
    const classes = useStyles()
    
    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={2} display="flex" alignItems={"center"}>
                <Box>
                    <img 
                        src={process.env.PUBLIC_URL + '/img' + picture}
                        className={classes.productimage}
                    />
                </Box>
            </Grid>
            <Grid item xs={10} display="flex" alignItems={"center"}>
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Typography sx={{ width: '100%'}} textAlign="left">
                            Name: {name}
                        </Typography>
                    </Grid>                  
                    <Grid item xs={12} display="flex" alignItems={"left"}>
                        <Typography textAlign="left">Title: {product_view}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems={"left"}>
                        <Typography textAlign="left">
                            Quantity: {quantity}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems={"left"}>
                        <Typography textAlign="left" sx={{ fontWeight: 'bold', fontSize:'1.1rem'}}>Price: ${price}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    )
}


export default SmallView