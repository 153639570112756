import './App.css';
import { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"

// import { httpClient } from './services/HttpClient';
import AppBar from './components/bar/AppBar';
import FootBar from './components/bar/FootBar';
// import RenderOnRole from './components/auth/RenderOnRole'
// import StakeholderBar from './components/stakeholder/StakeholderBar';
// import AdminBar from './components/bar/AdminBar';
// import AdminUsersPage from './components/old/AdminUsersPage';
// import AdminCompaniesPage from './components/admin/AdminCompanyPage';
// import ManageCompanyPage from './components/stakeholder/ManageCompanyPage'
// import AdminProductsPage from './components/admin/AdminProductsPage';


import { BrowserRouter as Router } from 'react-router-dom'
import HomePage from './components/Home';
import ListProductPage from './components/company/ListProductPage';
import ProductPage from './components/company/ProductPage';
// import MissngPage from './components/Missing';
import Box from '@mui/material/Box';
import ProfilePage from './components/auth/Profile';
// import CompanyPage from './components/company/CompanyPage';
import AboutPage from './components/MissionPage';
import NewCompanyPage from './components/company/NewCompanyPage';
import CartEditPage from './components/orders/CartEditPage';
// import OrdersPage from './components/orders/OrdersPage';
import CartPage from './components/orders/CartPage';
import CheckoutPage from './components/orders/CheckoutPage';
// import QRPage from './components/company/QRPage';
import InfluencerPage from './components/stakeholder/InfluencerPage';
import FollowPage from './components/stakeholder/FollowPage';
import ServicesPage from './components/stakeholder/ServicesPage';
import Influencer from './components/stakeholder/Influencer';
// import useRoles from './components/auth/useRoles'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import jwtCheck from './services/jwtCheck';
import BadgeDialog from './components/badge/BadgeDialog'
import Confetti from './components/badge/Confetti'
import { useAppDispatch, useAppSelector } from './app/hooks';
import { stakeholderLogin, stakeholderLogout } from './features/login';
import { loginLoad, loginComplete } from './features/loading';
import { fetchCompanyData } from './features/viewcompany';


const theme = createTheme({
  // more found here: https://mui.com/material-ui/customization/default-theme/#main-conten
  palette: {
    primary: {
      main: '#13271C',
    },
    secondary: {
      main: '#336749',
    },

  }
})

function App() {
  const primaryColor  = useAppSelector((state) => state.viewcompany.primary_color)
  const [viewNewBadge,setViewNewBadge]=useState(false);
  const [newBadgeData,setNewBadgeData]=useState({});
  const isAuth = useAppSelector((state) => state.loginstat.value)

  // const history = useNavigate();
  // const [update,setUpdate]=useState(false)
  // const [showBadges,setShowBadges]=useState(false);
  // const [ifAdmin,setIfAdmin] = useState(false);
  const dispatch = useAppDispatch()

 

  useEffect(() => {
    dispatch(loginLoad())
    jwtCheck.initKeycloak(authUpdate)
    updateCompany(0)
  }, []);

  const authUpdate = (stat) => {
    // setUpdate(!update)
    // setNewBadgeData(badge_data)
    // setViewNewBadge(true)
    if (stat) {
      dispatch(stakeholderLogin())
      dispatch(loginComplete())
      console.log('CALLBACK')


    } else {
      dispatch(stakeholderLogout())
      dispatch(loginComplete())
    }
  }

  const updateCompany = async (comp) => {
    if (comp !== 0 ) {
      console.log(comp)
      dispatch(fetchCompanyData(comp))
    }
  }

  const newBadge = (badge_data) => {
    // setUpdate(!update)
    setNewBadgeData(badge_data)
    setViewNewBadge(true)
  }

  const closeBadgeDialog = () => {
    // setUpdate(!update)
    setNewBadgeData({})
    setViewNewBadge(false)
  }


  return (
    <div>
        <ThemeProvider theme={theme}>
          <Router>
              <Box className="App" sx={{ backgroundColor: primaryColor }}>
                <AppBar SelectCompany={(company_id)=>{updateCompany(company_id)}}/>
                {/* <RenderOnRole roles={['admin']} showNotAllowed>
                    <AdminBar/>
                </RenderOnRole>   */}
                {/* <StakeholderBar company={company} show={showBadges} update={update}/> */}
                <BadgeDialog open={viewNewBadge} data={newBadgeData} onClose={()=>{closeBadgeDialog()}}/>
                <Confetti open={viewNewBadge}/>
                <Routes>
                <Route path="/" 
                    element={<HomePage isAuth={isAuth} SelectProduct={(company_id)=>{updateCompany(company_id)}}/>}   
                  />
                  <Route path="/profile" element={<ProfilePage/>} />
                  {/* <Route path="/admin/users" element={<AdminUsersPage/>} /> */}
                  {/* <Route path="/admin/companies" element={<AdminCompaniesPage/>} /> */}
                  {/*<Route path="/management/company/:id" element={<ManageCompanyPage/>} />
                  <Route path="/admin/products" element={<AdminUsersPage/>} /> */}
                  <Route path="/cart" element={<CartPage/>} />
                  <Route path="/checkout" element={<CheckoutPage/>} />
                  <Route path="/mission" element={<AboutPage/>} />
                  <Route path="/new/company" element={<NewCompanyPage />} />
                  <Route path="/company/:company" element={<ListProductPage/>}/>
                  {/* <Route path="/company/:company/about" element={<CompanyPage company={company} SelectProduct={(company_id)=>{updateCompany(company_id)}}/>} /> */}
                  <Route path="/company/:company/product/:product" element={<ProductPage />} />
                  
                  {/*<Route path="/company/:company/review" element={<ReviewPage company={company} />} />
                  <Route path="/company/:company/qr" element={<QRPage SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} />*/}
                  <Route path="/company/:company/order/:id" element={<CartEditPage/>} />
                  {/* <Route path="/company/:company/orders" element={<OrdersPage company={company} SelectCompany={(company)=>{setCompany(company); setShowBadges(false)}}/>} /> */}
                  <Route path="/influencer" element={<InfluencerPage SelectProduct={(company_id)=>{updateCompany(company_id)}} />} />
                  <Route path="/follow" element={<FollowPage SelectProduct={(company_id)=>{updateCompany(company_id)}} />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/i/:id" element={<Influencer SelectCompany={(company)=>{updateCompany(company)}} update={()=>{newBadge()}} />} />
                  {/* <Route path="*" element={<MissingPage />} />*/}
                </Routes>
                <Box className="App-footer">
                  <FootBar SelectCompany={()=>{updateCompany("1")}}/>
                </Box>
              </Box>
          </Router>
        </ThemeProvider>
    </div>
  );
}

export default App;
