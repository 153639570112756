import axios from 'axios';
import jwtCheck from '../../services/jwtCheck';

// axios.defaults.headers.common['Authorization'] = 'Bearer test';

const apit = axios.create({
  // export default axios.create({
    baseURL: 'https://brackettinnovations.com/api',
    // baseURL: 'http://localhost:5000',
    withCredentials: true,
    // headers: {
    //   Authorization: 'Bearer ' + UserService.getToken()
    // }
});

apit.interceptors.request.use(
  (config) => {
    const token = jwtCheck.getToken(); // Get the token from your UserService

    if (token) {
      // If the token exists, add it to the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // If the token does not exist, remove the Authorization header
      delete config.headers.Authorization;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//     response => {
//       return response;
//   },
//   async error => {
//       if(error.response.status == 401) {
//         console.log('Check Token')
//           // let token = await refreshToken();
//           // axios.defaults.headers.common['Authorization'] = 'Bearer refrease';
//       }
//   }
// );
apit.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Avoid looping on retry
      try {
        // Attempt to update the token if it’s expired
        const refreshed = await jwtCheck.updateToken(); // Ensure `updateToken()` is defined in jwtCheck
        if (refreshed) {
          // Set the new token in the Authorization header
          const newToken = jwtCheck.getToken();
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          // Retry the original request with the new token
          return apit(originalRequest);
        } else {
          // If the token refresh fails, log out or redirect as needed
          jwtCheck.doLogout();
        }
      } catch (err) {
        console.error('Token refresh failed', err);
        jwtCheck.doLogout();
        return Promise.reject(error);
      }
    }
    // if (error.response && error.response.status === 422) {
    //   console.warn("Validation error:", error.response.data);
    //   // Optionally: You can add custom behavior here, such as emitting an event or setting a global state
    //   return Promise.resolve({ suppressed: true, data: error.response.data });
    // }

    return Promise.reject(error);
  }
);

export default apit;
