import { useState } from 'react'
// import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper, Grid } from '@mui/material';
import InfluencerList from './InfluencerList';


const ServicesPage = ({SelectCompany}) => {
    const [buttonValue, setButtonValue]=useState('Influencer');
    // const [buttonValue, setButtonValue]=useState('Influencer');
    
    
    return (
        <Box> 
            <Paper className="App-paper-full">
                <Grid item xs={12}>
                <Typography variant="h3" textAlign="center">Services</Typography> 
                </Grid>
            </Paper>
            { buttonValue === 'Influencer' ?
                <InfluencerList/>
            :
                <Box></Box>
            }
            
                    
                
                <br></br>
        </Box>
    )
}

export default ServicesPage