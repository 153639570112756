import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

const cartlistSlice = createSlice({
  name: 'cartlist',
  initialState,
  reducers: {
    uploadCartItem(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      // console.log('made it')
      return { ...state, ...action.payload };
    },
    resetCartItem(state) {
        // if (comp.payload.id != 0) {
        //   return comp.payload
        // }
      return initialState;
    }
    // decrement
    // reset
  },
});

export const { uploadCartItem, resetCartItem } = cartlistSlice.actions;
export default cartlistSlice.reducer;