import React from 'react'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import NewCompanyInputA from './NewCompanyInputA';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const initialValues = {
  title: '',
  name_view: '',
  description: '',
  influencer_usd: '',
  price: '',
  official_website: ''
};

const LoggedOutNewCompany=()=>{
    return (
        <h1>You are not logged in, redirect to login page please</h1>
    )
}


// const LoggedInNewCompany=()=>{
const LoggedInNewCompany=({updateBadges})=>{

  return (
    <Grid 
        justifycontent="center"
        alignitems="center"
    >
      <Grid item xs={12}>
        <Paper elevation={5} className="App-paper-full">
          <Typography align="left" variant="body1">
            Initial phase in adding a company to the Brackett Innovations product list.  Business Owner or Employee 
            seeking to leverage the benefits offered by Brackett Innovations. You must have the capability to ship products 
            to anyware in the US.
          </Typography>
          {/* <Typography align="left" variant="body1">
            Eligibility to Add a Company to Brackett Innovations:
          </Typography>
          <ul>
            <li style={{ textAlign: 'left' }}>
              Business Owner or Employee seeking to leverage the benefits offered by Brackett Innovations.
            </li>
            <li style={{ textAlign: 'left' }}>
            Influencers interested in endorsing products they genuinely enjoy, contributing to sales growth and profitability.
            </li>
          </ul> */}
          <Typography align="left" variant="body1">
              Requirements:
              <ul>
                <li style={{ textAlign: 'left' }}>
                Maintain consistent pricing - The price of each product on Brackett Innovations should mirror that listed on your 
                official business website.
                </li>
                <li style={{ textAlign: 'left' }}>
                Define a finders fee - To incentivize influencers and encourage successful sales, your business should establish a clear 
                and defined finders fee.
                </li>
              </ul>
          </Typography>
          
        </Paper>
        <Paper elevation={5} className="App-paper-full">
          {/* <NewCompanyInputA initialValues={initialValues}/> */}
          <NewCompanyInputA initialValues={initialValues} updateBadges={(data)=>{updateBadges(data)}}/>
        </Paper>
        <Paper elevation={5} className="App-paper-full">
          <Typography align="left" variant="body1">
              Input:
          </Typography>
          <ul>
            <li style={{ textAlign: 'left' }}>
            Company Name: The official name of the company presented
            </li>
            <li style={{ textAlign: 'left' }}>
            Company Title: A short description of the products and merchandice sold at this company
            </li>
            <li style={{ textAlign: 'left' }}>
            Official Website: The official company website
            </li>
            <li style={{ textAlign: 'left' }}>
            Merchandice Price: Price in US dollars for how much the leading merchandice sells for on the official website
            </li>
            <li style={{ textAlign: 'left' }}>
            Finder's Fee Percent: This is the marketing commission, in percent or product, allocated to influencers for 
            their role in driving product sales. This fee must be endorsed by the company and is recomended 10 Percent.  
            </li>
            <li style={{ textAlign: 'left' }}>
            Company Notes: Provide a comprehensive description of the company or products being sold or include any additional notes that 
            will aid in streamlining the company submission process.
            </li>
          </ul>
        </Paper>
      </Grid>
    </Grid>  
  )
}


// const NewCompanyPage = () => {
const NewCompanyPage = ({newBadge}) => {
  const logged = useAppSelector((state) => state.loginstat.value)

  return (
      <div className="home container">
          {logged?<LoggedInNewCompany updateBadges={(data)=>{newBadge(data)}}/>:<LoggedOutNewCompany/>}
      </div>
  )
}


export default NewCompanyPage