import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

const theme = createTheme({
  palette: {
    color: 'white',
    background: {
      paper: '#282c34',
    },
  },
});


const useStyles = makeStyles({
  badgeimage: {
    backgroundColor: '#282c34',
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#282c34', // Change this to the desired color for the Paper
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items horizontally
    textAlign: 'center', // Center text
  },
})

const BadgeDialog = ({ open, onClose, data}) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} >
        {/* <DialogTitle style={{ textAlign: 'center', color: 'white' }}>{data.company_name}</DialogTitle> */}
        <DialogTitle style={{ textAlign: 'center', color: 'white' }}>{"New Company"}</DialogTitle>
        <DialogContent>
          <Paper className={classes.paper}>
            <Avatar 
              // src={process.env.PUBLIC_URL + '/img/' + data.badge_photo}
              src={process.env.PUBLIC_URL + '/img/badges/new_company_light.png'}
              sx={{ width: 150, height: 150 }}
              className={classes.badgeimage}
            />
            </Paper>
          {/* <DialogContentText sx={{ color: "white" }}><p>You have recived a new company badge! </p> <p>{data.badge_description}</p></DialogContentText> */}
          <DialogContentText sx={{ color: "white" }}><p>You have recived a new company badge! </p> <p>You will get an email soon on how to proceed</p></DialogContentText>
          {/* <DialogContentText sx={{ color: "white" }}><p>You have recived a {data.badge_name} badge! </p> <p>{data.badge_description}</p></DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BadgeDialog;