import React from 'react'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const useStyles = makeStyles({
        name: {
            color: (props) => props.primary_color,
            width: '100%',
            marginRight: '.5em',
        },
        navlogosm: {
          color: '#9BCDB0',
          width: '5px',
          marginRight: '.5em',
      },
      })


// const Product = ({name_view,logo,description,title,cover,primary_color,secondary_color}) => {
const ProductThumb = (props) => {
    // const {name_view,price,company_logo,company_name,sold, gifted,title,picture_location,primary_color,secondary_color,review,review_total} = props
    const logo_img_path=process.env.PUBLIC_URL + '/img' +  props.company_logo
    const cover_img_path=process.env.PUBLIC_URL + '/img' + props.picture_location
    
    const classes=useStyles(props)
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
          <Card  sx={{ maxWidth: '500px', m: '3px', pt: '2px' }}>
          <CardMedia
            component="img"
            height="194"
            image={cover_img_path}
            alt={props.name_view}
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '175px',
              objectFit: 'contain',
            }}
          />
          <CardContent sx= {{ width: '100%', m: '0px', p: '0px'}}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={2} sx={{p: '4px'}}>
                <Avatar alt={props.name_view}
                src={logo_img_path}
                sx={{ width: '2rem', height: '2rem' }}
                // className={classes.navlogosm}
                
                >
                  {props.name_view}
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1" align="left" ml='4px' color="text.secondary">
                  {props.company_name}
                </Typography>
                <Typography variant="body2" align="left" ml='4px' color="text.secondary">
                  {props.name_view}
                </Typography>
            </Grid>
            </Grid>
          </CardContent>
          <CardContent sx= {{ width: '100%', m: '0px', p: '0px'}}>
            <Typography variant="body1" align="left" ml='4px' color="text.secondary">
              {props.title}
            </Typography>
          </CardContent>
          <CardContent sx={{display: 'flex', width: '100%', m: '0px', p: '0px'}}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={props.sold_quant ? 6 : 4}>
                <Typography variant="body2" m='2px' color="text.secondary">
                  {props.sold_quant ?
                    <div>
                      {props.sold_quant}/{props.sold+props.backlogged} sold
                    </div>
                  :
                    <div>
                      {props.sold} sold
                    </div>
                  }
                  
                </Typography>
              </Grid>
              <Grid item xs={props.sold_quant ? 0 : 4} sx={{border: 'sold red'}}>
                <Typography variant="body2" m='2px' color="text.secondary">
                  {props.gifted} gifted
                </Typography>
              </Grid>
              <Grid item xs={props.sold_quant ? 6 : 4}>
                <Typography variant="body2" m='2px' color="text.secondary">
                  {props.review}⭐({props.review_total})
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{display: 'flex', width: '100%', m: '0px', p: '0px'}}>
            <Typography variant="h6" className={classes.name} sx={{border: `solid ${props.primapry_color}`}}>
              ${props.price}
            </Typography>    
          </CardActions>
        </Card>
        <br></br>
      </div>    
    )
}

export default ProductThumb

