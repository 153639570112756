import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import { Paper, Grid, Box, tabClasses } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import NameEditor from './NameEditor'
import ImageEditor from './ImageEditor'
import {useForm} from 'react-hook-form'
import TextEditor from './TextEditor'
import apit from '../api/authApi';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    navlogosm: {
        color: '#9BCDB0',
        width: '200px',
        marginRight: '.5em',
    },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const GiftMediaInput = ({quantity,cart_id,color, updateCarts}) => {
    const isAuth = useAppSelector((state) => state.loginstat.value)
    // const [password,setPassword]=useState('');
    // const [image,setImage]=useState('');
    // const [text,setText]=useState('');
    // const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm();
    const [mediaInputs,setMediaInputs]=useState({
        'textreq':1,
        'imagereq':0,
        'text':1,
        'image':1,
        'sound':0
    });
    // const [pwd, setOpenText] = useState(false)
    const [image, setImage] = useState(false)
    const classes = useStyles()
    const [openText, setOpenText] = useState(false)
    const [openPassword, setOpenPassword] = useState(false)
    const [viewPassword, setViewPassword] = useState('')
    const [viewText, setViewText] = useState('')
    const [openImage, setOpenImage] = useState(false)
    const [picture, setPicture] = useState(null)

    useEffect(()=> {
        getDBData()
    },[]);

    const getDBData = () => {
        apit.get(`/api/order/cart/${cart_id}/giftmedia`).then(data => {
            // setPassword(data.)
            // setImage(data.image)
            // setText(data.text)
            console.log(data.data)
            if (data.data.length>0) {
                const pwd=findPasswordContents(data.data, 'PASSWORD')
                const txt=findPasswordContents(data.data, 'TEXT')
                const img=findPasswordContents(data.data, 'IMAGE')
                if (pwd) {
                    setValue("password", pwd)
                    setViewPassword(pwd)
                }
                if (txt) {
                    setValue("content", txt)
                    setViewText(txt)
                }
                if (img) {
                    setImage(img)
                }

            }

            // setValue("password", pwd)
            console.log(data.data)
        })
    };

    const findPasswordContents = (data,value) => {
        const result = data.find((item) => item.media === value);
        console.log(result)
        return result ? result.content : '';
    };

    const handleEditTextClose = () => {
        setOpenText(false);
    };
    const handlePasswordClose = () => {
        setOpenPassword(false);
    };
    
    const handleAddPictureClose = () => {
        setOpenImage(false);
    };

    const handleFileChange=e=>{
        // setPicture(e.target.files[0])
        setPicture(e.target.files[0]);
    }

    const EditText = (data) => {
        //     setEditProfileOpen(false);
            console.log(data)
            const body = {
                    content: data.content,
                    cart_id: cart_id,
                    cartaddon_id: 0,
                    type_media: 'TEXT',
                    gift: true
                }
    
            apit.post(`/api/order/cart/mediaslice`,body).then(data => {
                console.log(data.data)
                const txt=findPasswordContents(data.data, 'TEXT')
                if (txt) {
                    setValue("content", txt)
                    setViewText(txt)
                }
                setOpenText(false);
            })
        }

        const handleChange = () => {
            console.log('update public')
        };

        const EditPassword = (data) => {
        //     setEditProfileOpen(false);
            console.log(data)
            const body = {
                    content: data.password,
                    cart_id: cart_id,
                    cartaddon_id: 0,
                    type_media: 'PASSWORD',
                    gift: true
                }
    
            apit.post(`/api/order/cart/mediaslice`,body).then(data => {
                // getDBData()
                const pwd=findPasswordContents(data.data, 'PASSWORD')
                if (pwd) {
                    setViewPassword(pwd)
                }
                setOpenPassword(false);
            })
        }

        
        const EditMediaslicePicture = (data) => {
            //     // const storageRef="/home/jbrackett/invest/investment/client/public/"
        
                const body = new FormData()
                body.append("picture_name", picture)
                body.append("cart_id", cart_id)
                body.append("cartaddon_id", 0)
                body.append("gift", true)
                console.log(body.get('picture_name'))
                console.log(picture)
        
                // apit.post('/api/auth/profile/picture',body).then(data => {
                //     console.log(data.data)
                //     // dispatch(uploadCartItem({}));
                //     // dispatch(fetchCompanyData(0))
                //     // window.location.href = 'http://localhost:3000/cart';
                // })
                apit.post('/api/order/mediaslice/picture', body, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        'type':'formData',
                    }
                }).then(data => {
                    console.log(data.data);
                    // getDBData()
                    setImage(data.data.content)
                    setOpenImage(false);
                }).catch(error => {
                    console.error('Error:', error);
                });
        
            }

    return (
        <div>
            {isAuth?
                <></>
            :
                <Paper elevation={5} className="App-paper-full">
                    Please Login to access this page
                </Paper>
            }
            <Grid 
                container
                // direction="row-reverse"
                justify="center"
                alignitems="center"
                disply='flex'
            >
                {image?
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <img src={process.env.PUBLIC_URL + '/img' + image} className={classes.navlogosm} alt='Image'/>
        
                    </Grid>
                :
                <div></div>

                }
                <Grid item xs={12} display="flex" alignItems="center">
                    <Typography sx={{width: '100%'}} variant="subtitle1" ml='10px' textAlign="center">{viewText}</Typography>
                </Grid>
            {/* <Grid item xs={12} sx={{border:`solid ${color}`, padding:'10px', borderWidth: .5, borderRadius: 2}}> */}
            </Grid>
            <Grid item xs={12} md={12} alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                >
                    <Grid item xs={6} md={6} sx={{display: 'flex' }} alignItems={"center"}>
                        {viewText ?
                            <Button
                                onClick={()=>{setOpenText(true)}}
                                // onClick={deleteWish}
                                variant={"outlined"}
                                onClose={()=>{setOpenText(false)}}
                                color="success"
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                            >
                                Edit Text
                            </Button>
                            :
                            <Button
                                onClick={()=>{setOpenText(true)}}
                                // onClick={deleteWish}
                                variant={"outlined"}
                                onClose={()=>{setOpenText(false)}}
                                color="error"
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                            >
                                Add Text
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={6} md={6} sx={{display: 'flex'}} alignItems={"center"}>
                        <Button
                            onClick={()=>{setOpenImage(true)}}
                            variant={ "outlined"}
                            color="success"
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            width: '90%',
                            }}
                        >
                            {image? 'Edit Image':'Add Image'}
                        </Button>
                    </Grid>
                    {viewPassword ?
                    <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                        disply='flex'
                    >
                        <Grid item xs={6} display="flex" alignItems="center" sx={{border: '1px dotted grey', borderRadius: 2}}>
                            <Typography sx={{width: '100%'}} variant="subtitle1" ml='10px' textAlign="center">Pwd: {viewPassword}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={()=>{setOpenPassword(true)}}
                                // onClick={deleteWish}
                                variant={"outlined"}
                                onClose={()=>{setOpenPassword(false)}}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                                    >
                                Edit pwd
                            </Button>
                        </Grid>
                        {/* <Grid item xs={6} sx={{mr:'10px'}}>
                            <FormGroup>
                                <FormControlLabel
                                control={
                                    <Switch checked={true} onChange={handleChange} name="public" />
                                }
                                label="Public"
                                labelPlacement="start"
                                alineself='left'
                                style={{ marginRight: 'auto' }}
                                />
                            </FormGroup>
                        </Grid> */}
                    </Grid>
                    :
                    <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                        disply='flex'
                    >
                        <Grid item xs={6}>
                            <Button
                                onClick={()=>{setOpenPassword(true)}}
                                // onClick={deleteWish}
                                variant={"outlined"}
                                onClose={()=>{setOpenPassword(false)}}
                                color={quantity>1? "error":"success"}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                                    >
                                Add password
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{border:`1px dotted ${color}`, padding:'10px', borderWidth: .5, borderRadius: 2}}>
                            Required for quantites greater than 1
                        </Grid>
                     </Grid>
                }           
                </Grid>
            </Grid>


            <Dialog 
                    open={openText}
                    onClose={handleEditTextClose}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle>Update Name</DialogTitle>
                   <form 
                        noValidate 
                        autoComplete="off"
                    >
                    <DialogContent>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="text"
                                {...register("content", { required: true, maxLength: 250 })}
                                className={classes.field}
                                label="Content"
                                required
                                multiline
                                rows={3}
                                error={errors.content}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {errors.content?.type === "required" && <p style={{ color: "red" }}><small>Content is required</small></p>}
                                {errors.contnet?.type === "maxLength" && <p style={{ color: "red" }}><small>Content must be less than 250 characters</small></p>}
                            </div> 
                            
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleEditTextClose}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={handleSubmit(EditText)}
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
                <Dialog 
                    open={openPassword}
                    onClose={handlePasswordClose}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle>Password</DialogTitle>
                   <form 
                        noValidate 
                        autoComplete="off"
                    >
                    <DialogContent>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="text"
                                {...register("password", { maxLength: 25 })}
                                className={classes.field}
                                label="Password"
                                defaultValue={viewPassword}
                                error={errors.password}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {/* {errors.password?.type === "required" && <p style={{ color: "red" }}><small>Content is required</small></p>} */}
                                {errors.password?.type === "maxLength" && <p style={{ color: "red" }}><small>Content must be less than 25 characters</small></p>}
                            </div> 
                            
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handlePasswordClose}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            // onClick={()=>{console.log('data');handleSubmit(EditText)}}
                            onClick={handleSubmit(EditPassword)}
                            // onClick={handleSubmit(EditPassword)}
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>              
                <Dialog
                    maxWidth='xs'
                    fullWidth
                    open={openImage} 
                    onClose={handleAddPictureClose}
                    size="large"
                >
                    <DialogTitle>Update Profile Picture</DialogTitle>
                    <DialogContent>
                     <TextField
                        autoFocus
                        id="picture"
                        type="file"
                        accept=".png,.jpg"
                        fullWidth
                        variant="outlined"
                        onChange={handleFileChange}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={handleAddPictureClose}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.btn}
                        onClick={EditMediaslicePicture}
                    >
                        Update
                    </Button>
                    </DialogActions>
                </Dialog> 
        </div>
    )
}


export default GiftMediaInput