import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TasksTool from '../old/Tasks'
import InfluencerItem from './InfluencerItem'
// import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Container, Grid } from '@mui/material';
import api from '../api/companies';
import apit from '../api/authApi';
import followInfluencer from '../../features/followinfluencer';
import viewInfluencer from '../../features/viewcompany';
import { followInfluencerSet } from '../../features/followinfluencer';


const FollowPage = ({SelectCompany}) => {
    const isAuth = useAppSelector((state) => state.loginstat.value)
    const influencerId = useAppSelector((state) => state.followinfluencer.id)
    const [influencers,setInfluencers]=useState([]);
    const dispatch = useAppDispatch()

    useEffect(()=> {

        const getInfluencers = async () => {
        
            try {
                const response = await api.get(`/api/stakeholder/influencer/${influencerId}`);
                    setInfluencers([response.data])
            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
       
                }
            }
        }

        const getAuthInfluencers = async () => {
        
            try {
                apit.get('/api/stakeholder/influencers').then(data => {
                    setInfluencers(data.data)
                })
                .catch(error => console.log(error));
            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
       
                }
            }
        }

        // if (isAuth) {
        getAuthInfluencers()
        // } else {
            // getInfluencers();
        // }
    
    },[]);

    return (
        <div className="home container">
            <Grid 
                container
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
            >
                
                {
                    influencers.map(
                        (influencer,index)=>(
                            <Link
                                onClick={()=>{dispatch(followInfluencerSet(influencer))}}
                                style={{ textDecoration: 'none' }}
                                to={`/i/${influencer.user_entity_id}`}
                            >
                                <Grid item xs={12}>
                                    <InfluencerItem key={index}
                                        id={influencer.user_entity_id}
                                        picture={influencer.picture_location}
                                        username={influencer.username}
                                        companies={influencer.row_count}
                                        views={influencer.views}
                                    />
                                
                                </Grid>
                            </Link>
                        )
                    )
                }
                    
                
                <br></br>
            </Grid>
        </div>
    )

}


export default FollowPage