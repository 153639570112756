import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const WishMerchandice = ({picture,manufacture_phase,total,description,name,merch_backlogged,prod_backlogged,merch_gift_pending,prod_gift_pending,merch_manufacture_days,prod_manufacture_days,max_quantity,merch_quantity,cart_quantity,influencer,checked,company,primary_color,title,changeClick,addon=false}) => {
    const classes = useStyles();

    const changeClicked = () => {
        changeClick()
    }


    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={1} display="flex" alignItems={"center"}>
                <Box sx={{ paddingRight: '.5em' }}>
                    <Checkbox {...label}  onClick={()=>(changeClicked())} checked={checked}/>
                </Box>
            </Grid>
            <Grid item xs={3} md={2} display="flex" alignItems={"center"}>
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + picture}
                                className={classes.productimage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} md={9} display="flex" alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="left"
                    >
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                        <Typography textAlign="left" variant="title">{title}</Typography>
                    </Grid>    
                    {company ?
                        <Grid item xs={12} display="flex"  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                            <Typography sx={{ width: '100%'}} textAlign="left">
                               Company: {company}
                            </Typography>
                        </Grid>
                    :
                        <></>
                    }
                    {name ?
                        <Grid item xs={12} display="flex"  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                            <Typography sx={{ color: primary_color, width: '100%'}} textAlign="left">
                               Name: {name}
                            </Typography>
                        </Grid>
                    :
                        <></>
                    }
                    
                    {influencer?
                        <Grid item xs={12}  sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                            <Typography textAlign="left" sx={{ width: '100%'}}>Influencer: {influencer}</Typography>
                        </Grid>
                    :
                        <div></div>
                    } 
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                        <Typography textAlign="left">{description}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                        <Grid 
                            container
                            // direction="row-reverse"
                            justify="center"
                            alignitems="left"
                            sx={{
                                borderRadius: 2,
                                zIndex: 10,
                                backgroundColor: (cart_quantity>(merch_quantity-merch_backlogged) || cart_quantity==max_quantity)  ? "rgba(255, 165, 0, 0.1)": "transparent",
                                border: '1px dotted grey'
                            }}
                        >
                            <Grid item xs={12} display="flex"  alignItems={"left"}>
                                <Typography sx={{  width: '100%', paddingLeft: '.5em' }} textAlign="left">
                                    Production: {manufacture_phase}
                                </Typography>
                            </Grid>
                            {cart_quantity>(merch_quantity-merch_backlogged)  ? 
                                <Grid 
                                    container
                                    // direction="row-reverse"
                                    justify="center"
                                    alignitems="left"
                                    justifycontent="flex-start"

                                >
                                    <Grid item xs={12} display="flex" justifycontent="flex-start"  sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  width: '100%'}} textAlign="left">
                                            Out of Stock:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifycontent="flex-start"  sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  width: '100%'}} textAlign="left">
                                            {merch_quantity} In Stock| {prod_backlogged-prod_gift_pending} Pending
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifycontent="flex-start"  sx={{ paddingLeft: '1em', backgroundColor: cart_quantity>0  ? "rgba(255, 165, 0, 0.1)": "transparent"}} alignItems={"left"}>
                                        <Typography sx={{  pr: '.5rem',pl:'.25rem', borderRadius: 1, border: `1ps solid ${primary_color}`,display: 'inline-block'}} textAlign="left">
                                            Est. Delay: {prod_manufacture_days} Days
                                        </Typography>
                                    </Grid>
                                </Grid>
                            :
                                <Grid 
                                    container
                                    // direction="row-reverse"
                                    justify="center"
                                    alignitems="left"
                                    justifycontent="flex-start"
                                >
                                    <Grid item xs={12} display="inline-block"  justifycontent="flex-start" sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  width: '100%'}} textAlign="left">
                                            {merch_quantity} In Stock| {merch_backlogged-merch_gift_pending} Pending
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifycontent="flex-start"  sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  pr: '.5rem',pl:'.25rem',  borderRadius: 1, border: `1px solid ${primary_color}`,display: 'inline-block'}}  justifyContent="flex-start" textAlign="left">
                                            Est. Delay: {merch_manufacture_days} Days
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {cart_quantity==max_quantity && cart_quantity>(merch_quantity-merch_backlogged) ? 
                                    <Grid item xs={12} display="block"  sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  width: '100%', fontSize: '0.875rem'}} textAlign="left">
                                            Personalized Manufacturing Restricts Capacity
                                        </Typography>
                                        <Typography sx={{  width: '100%'}} textAlign="left">
                                            Max Qty: {max_quantity}
                                        </Typography>
                                    </Grid>
                                :
                                    <Grid item xs={12} display="flex"  sx={{ paddingLeft: '1em'}} alignItems={"left"}>
                                        <Typography sx={{  width: '100%'}} textAlign="left">
                                            Max Qty: {max_quantity}
                                        </Typography>
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                        <Typography textAlign="left">
                            Quantity: {cart_quantity}
                        </Typography>
                    </Grid>
                     <Grid item xs={12}  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                        <Typography textAlign="left" variant="h5">Total: ${total}</Typography>
                    </Grid> */}
                   
                   
                    
                    {/* { xs: 'block', md: 'none' } */}
                </Grid>
            </Grid>


            <br></br>
        </Grid>
    )
}


export default WishMerchandice