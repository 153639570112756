import React, { useState } from "react";
import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import apit from '../api/authApi';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        width: '100%',
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})
export default function Card({close, address_id,tax_id, total,shipment_amount,showQR}) {
    const classes = useStyles()
    const [clientSecret, setClientSecret] = useState('');
    const [disabled, setDisabled] = useState(true)
    const [error, setError] = useState(false)
    const [response, setResponse] = useState('')
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
      if (!stripe || !elements) {
          return;
      }
      const body = {   
            address_id: address_id,
            tax_id:tax_id,
            shipment_id:shipment_amount,
            charge_total:total*100
        }

      apit.post(`/api/order/`,body ).then(data => {
        setClientSecret(data.data.client_secret);
        stripe.confirmCardPayment(
          data.data.client_secret,
          {
              payment_method: {
                  card: elements.getElement(CardElement),
              },
          }
      ).then(result => {
          if (result.error) {
              // Handle error (e.g., declined payment, network issues)
              console.error('Payment failed:', result.error.message);
              alert('Payment failed: ' + result.error.message);
              apit.delete(`/api/order/complete`,body )
          } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
              // Payment successful
              console.log('Payment succeeded:', result.paymentIntent);
              alert('Payment successful!');
              close(data.data.id)
              apit.post(`/api/order/complete`,body ).then(data2 => {
                // close(data2.data)
                setError(true)
                showQR(data2)
                setResponse(data2.username)
              }).catch((error) => {
                const { status, data3 } = error.response;
                if (error.response.status === 422) {
                    setError(true);
                    setResponse(data3.msg)
                } else {
                    console.error("Unexpected error:", error);
                }
            });
          } else {
              // Unexpected status
              console.warn('Unexpected payment status:', result.paymentIntent?.status);
              alert('Unexpected payment status: ' + result.paymentIntent?.status);
              apit.delete(`/api/order/complete`,body )
          }
      }).catch(error => {
        console.log(error)
          // Handle unexpected errors
          if (error.response) {
            const { status, data } = error.response;
            if (error.response.status === 422) {
                setError(true); // Set influencer error state
                setResponse(data.msg)
            } 
            else if (error.response.status === 500) {
                setError(true); // Set influencer error state
                setResponse(data.msg)
            } 
        }
          console.error('An error occurred during payment:', error);
          alert('An error occurred during payment. Please try again.');
          apit.delete(`/api/order/complete`,body )
      });
      })
    }
    const handleClose = () => {
        setError(false);
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
      };

      const cardStyle = {
        style: {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };

  return (
    // <form id="payment-form" onSubmit={handleSubmit}>
    <div>
      
        <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
        >
            <Grid item xs={12} display="flex" alignItems={"left"}>
                <Typography textAlign="left" sx={{ fontWeight: 'bold', fontSize:'1.1rem'}}>Total: ${total}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: '2em'}}>
                <Box sx={{minWidth: '320px'}}>
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                </Box>
                
            </Grid>
            <br></br>
            <Grid item xs={12}>
                <Button 
                    className={classes.btn}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Pay Now
                </Button>
            </Grid>

        </Grid>
        <Dialog
                open={error}
                onClose={handleClose}
                aria-labelledby="login-dialog-title"
                aria-describedby="login-dialog-description"
            >
                <DialogTitle id="login-dialog-title">
                {"Processing Error"}
                </DialogTitle>
                <DialogContent>
                    {response}
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setError(false)}}>Cancel</Button>
                </DialogActions>
            </Dialog>
    </div>
  );
}

