import Avatar from '@mui/material/Avatar';
import { Container, Grid , Paper} from '@mui/material';
import Typography from '@mui/material/Typography';

const InfluencerItem = ({key,id,picture,username,views,companies}) => {

    return (
        <Container>
            <Paper sx={{px: 0, mx: 0, py: '8px'}} elevation={5} className="App-paper-full" style={{ color: "black" , borderWidth: 1, borderRadius: 20, borderColor: 'black'}}>
            <Grid 
                container
                // direction="row-reverse"
                justify="center"
                alignitems="center"
            >
                <Grid item xs={2} display="flex" alignItems={"center"}>
                    <Avatar 
                        alt={username} 
                        src={process.env.PUBLIC_URL + '/img' + picture}
                        sx={{ width: 60, height: 60 , ml: '2px'}}
                    />
                </Grid>
                <Grid item xs={10} display="block" alignItems={"left"}>
                    <Typography textAlign="left" variant="h5">{username}</Typography>
                    <Typography variant="body2" m='2px' textAlign="left">Companies Influenced: {companies}</Typography>
                    <Typography variant="body2" m='2px' textAlign="left">Views: {views}</Typography>
                </Grid> 

            </Grid>
        </Paper>
        </Container>
    )
}


export default InfluencerItem