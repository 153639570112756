import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import NameEditor from './NameEditor'
import ImageEditor from './ImageEditor'
import {useForm} from 'react-hook-form'
import TextEditor from './TextEditor'
import apit from '../api/authApi';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const MediaInput = ({cartaddon_id,cart_id,color, updateCarts, gift=false}) => {
    const isAuth = useAppSelector((state) => state.loginstat.value)
    const [mediaList,setMediaList]=useState([]);
    const [error,setError]=useState(false);
    const [message,setMessage]=useState([]);
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const [mediaInputs,setMediaInputs]=useState([]);
    const classes = useStyles()
    const [openText, setOpenText] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const [picture, setPicture] = useState(null)

    useEffect(()=> {
        console.log(cartaddon_id)
        console.log(cart_id)
        updateAddons()
    },[]);

    const updateAddons = () => {
        apit.get(`/api/order/cart/${cart_id}/${cartaddon_id}/media`).then(data => {
            setMediaList(data.data)
            console.log(data.data)
        })

        apit.get(`/api/order/cart/${cart_id}/${cartaddon_id}/mediainput`).then(data2 => {
            setMediaInputs(data2.data)
            console.log(data2.data)
        })
    };

    const handleEditTextClose = () => {
        setOpenText(false);
    };
    const handleErrorClose = () => {
        setError(false);
    };
    
    const handleAddPictureClose = () => {
        setOpenImage(false);
    };

    const handleFileChange=e=>{
        // setPicture(e.target.files[0])
        setPicture(e.target.files[0]);
    }

    const EditText = (data) => {
        //     setEditProfileOpen(false);
            console.log(data)
            const body = {
                    content: data.content,
                    cart_id: cart_id,
                    cartaddon_id: cartaddon_id,
                    type_media: 'TEXT',
                    gift: false
                }
    
            apit.post(`/api/order/cart/mediaslice`,body).then(data => {
                updateAddons()
                setOpenText(false);
            }).catch(error => {
                // Check if the error response exists
                if (error.response) {
                    const { status, data } = error.response;
                    if (error.response.status === 422) {
                        setError(true); 
                        setMessage(data.msg)
                    }
                } else {
                    console.error("An unknown error occurred:", error);
                }
            });
        }

        const EditMediaslicePicture = (data) => {
            //     // const storageRef="/home/jbrackett/invest/investment/client/public/"
        
                const body = new FormData()
                body.append("picture_name", picture)
                body.append("cart_id", cart_id)
                body.append("cartaddon_id", cartaddon_id)
                console.log(body.get('picture_name'))
        
                // apit.post('/api/auth/profile/picture',body).then(data => {
                //     console.log(data.data)
                //     // dispatch(uploadCartItem({}));
                //     // dispatch(fetchCompanyData(0))
                //     // window.location.href = 'http://localhost:3000/cart';
                // })
                apit.post('/api/order/mediaslice/picture', body, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        'type':'formData',
                    }
                }).then(data => {
                    console.log(data.data);
                    updateAddons()
                    updateCarts()
                    setOpenImage(false);
                }).catch(error => {
                    // Check if the error response exists
                    if (error.response) {
                        const { status, data } = error.response;
                        if (error.response.status === 422) {
                            setError(true); // Set influencer error state
                            setMessage(data.msg)
                        }
                    } else {
                        console.error("An unknown error occurred:", error);
                    }
                });
        
            }

    return (
        <div>
            {isAuth?
                <></>
            :
                <Paper elevation={5} className="App-paper-full">
                    Please Login to access this page
                </Paper>
            }
            <Grid item xs={12} sx={{border:`solid ${color}`, padding:'10px', borderWidth: .5, borderRadius: 2}}>
                {mediaInputs.merc_media_describe}
            </Grid>
            {mediaList.map(
                (show_media,index)=>(
                    <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    {show_media.media=='TEXT'?
                        <Grid item xs={12}>
                            <TextEditor value={show_media.content} key={index} id={show_media.id} updateCarts={()=>{updateAddons()}}/>  
                        </Grid>
                    :
                        <></>
                    }
                    {show_media.media=='IMAGE'?
                        <Grid item xs={12}>
                            <ImageEditor value={show_media.content} key={index} id={show_media.id} updateCarts={()=>{updateAddons()}}/>  
                        </Grid>
                    :
                        <></>
                    }

                </Grid>
                ))
            }
            
            <NameEditor init_value={mediaInputs.name} cart_id={cart_id} cartaddon_id={cartaddon_id} updateCarts={()=>{updateCarts()}}/>
            <Grid item xs={12} sx={{border:`solid ${color}`, padding:'10px', borderWidth: .5, borderRadius: 2}}>
                {mediaInputs.media_describe}
            </Grid>
            <Grid item xs={12} md={12} alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                >
                    <Grid item xs={6} md={6} sx={{display: 'flex' }} alignItems={"center"}>
                        {mediaInputs.text>0?
                            <Button
                                onClick={()=>{setOpenText(true)}}
                                // onClick={deleteWish}
                                variant={"outlined"}
                                onClose={()=>{setOpenText(false)}}
                                color={mediaInputs.textreq? "error":"success"}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                            >
                                Add Text
                            </Button>
                            :
                            <></>
                        }
                    </Grid>
                    <Grid item xs={6} md={6} sx={{display: 'flex'}} alignItems={"center"}>
                        {mediaInputs.image>0?
                                <Button
                                    onClick={()=>{setOpenImage(true)}}
                                    variant={ "outlined"}
                                    color={mediaInputs.imagereq? "error":"success"}
                                    sx={{ 
                                    my: .5, 
                                    display: 'block',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    width: '90%',
                                    }}
                                >
                                    Add Image
                                </Button>
                        :
                            <></>
                        }
                    </Grid>             
                </Grid>
            </Grid>
            <Dialog 
                    open={openText}
                    onClose={handleEditTextClose}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle>Update Name</DialogTitle>
                   <form 
                        noValidate 
                        autoComplete="off"
                    >
                    <DialogContent>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="text"
                                {...register("content", { required: true, maxLength: 250 })}
                                className={classes.field}
                                label="Content"
                                required
                                multiline
                                rows={3}
                                error={errors.content}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {errors.content?.type === "required" && <p style={{ color: "red" }}><small>Content is required</small></p>}
                                {errors.contnet?.type === "maxLength" && <p style={{ color: "red" }}><small>Content must be less than 250 characters</small></p>}
                            </div> 
                            
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleEditTextClose}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={handleSubmit(EditText)}
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>                
                <Dialog
                    maxWidth='xs'
                    fullWidth
                    open={openImage} 
                    onClose={handleAddPictureClose}
                    size="large"
                >
                    <DialogTitle>Update Profile Picture</DialogTitle>
                    <DialogContent>
                     <TextField
                        autoFocus
                        id="picture"
                        type="file"
                        accept=".png,.jpg"
                        fullWidth
                        variant="outlined"
                        onChange={handleFileChange}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={handleAddPictureClose}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.btn}
                        onClick={EditMediaslicePicture}
                    >
                        Update
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth='xs'
                    fullWidth
                    open={error} 
                    onClose={handleErrorClose}
                    size="large"
                >
                    <DialogTitle>Error in upload</DialogTitle>
                    <DialogContent>
                        {message}
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={handleErrorClose}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                    </DialogActions>
                </Dialog> 
        </div>
    )
}


export default MediaInput