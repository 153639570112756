import React from 'react'
import Typography from '@mui/material/Typography';
import { Grid, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
        selectionImg: {
          color: '#9BCDB0',
          width: '70px',
          marginRight: '.5em',
      },
      })


const MerchandiceCheckbox = (props) => {
    const {merch, checked, label, onMerchClick,viewCheck=true,quantity} = props

    const classes=useStyles(props)


    const handleGridClick = () => {
        onMerchClick();
      };

    return (
        <Grid
            item
            xs={12}
            sx={{ pl: '7px', display: 'flex', alignItems: 'stretch', cursor: 'pointer' }}
            onClick={handleGridClick}
        >
            <Box sx={{height: '100%', alignItems: 'sretch', minWidth: '5ch', pr: '5px'}}>
                {/* <FormControlLabel
                sx={{width:'100%'}}
                required control={
                <Checkbox 
                defaultChecked 
                />}
                label="Label" /> */}

                {viewCheck ?
                    <Checkbox
                        {...label}

                        // checked={checked[props.merch.id] || false}
                        checked={checked}
                        onChange={(e) => e.stopPropagation()}
                    />
                    :
                    <></>
                }
                <Typography variant="H5" sx={{ mt: '10px', lineHeight: '.2', width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>${props.merch.price_usd}</Typography>
            </Box>    
            {props.merch.picture_location ?
                    <img src={process.env.PUBLIC_URL + '/img' + props.merch.picture_location} alt={props.merch.name_view} style={{ marginRight: '5px', maxHeight: '100px', minHeight: '20px', alignSelf: 'center' }} />
                :
                    <></>
            }
            
            <Box sx={{width: '100%'}}>
                <Typography variant="subtitle2" sx={{ mt: '10px', lineHeight: '.4', width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>{props.merch.name_view}</Typography>
                <Typography variant="body2" sx={{ mt: '10px',width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>{props.merch.describe}</Typography>
                <Box sx={{width: '100%', display: 'block' }}>
                <Typography variant="body2" sx={{ flexShrink: 0, textAlign: 'left', alignSelf: 'center', borderRadius: 2, backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>Stock Quantity: {props.merch.quantity}</Typography>
                {!props.merch.add_quantity && quantity==(props.merch.maxover-props.merch.prod_backlogged) ?
                    <Typography variant="body2" sx={{ fontSize: '0.75rem',flexShrink: 0, textAlign: 'left', alignSelf: 'center', backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>
                        Due to Limited Manufcaturing Capabilities the Maximum Quantity: {props.merch.maxover-props.merch.prod_backlogged}
                    </Typography>
                :
                    <div></div>
                }
                {quantity>(merch.quantity-merch.backlogged) ?
                        <Grid>
                            <Typography variant="body2" sx={{ flexShrink: 0, textAlign: 'left', alignSelf: 'center', backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>Product Backlogged: {props.merch.prod_backlogged}</Typography>
                            {props.merch.limited_edition ?
                                <Typography variant="body2" sx={{ fontSize: '0.75rem',flexShrink: 0, textAlign: 'left', alignSelf: 'center', backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>
                                    This is an Limited Edition anything ordered outside of Stock may be slightly different in color or style
                                </Typography>
                            :
                                <div></div>
                            }
                            {props.merch.add_quantity ?
                                <Typography variant="body2" sx={{ flexShrink: 0, textAlign: 'left', alignSelf: 'center', backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>
                                    Maximum Quantity: {(props.merch.quantity-props.merch.backlogged)+(props.merch.maxover-props.merch.prod_backlogged)}
                                </Typography>
                            :
                                <div></div>
                            }
                            
                            <Typography variant="body2" sx={{ pb: '10px',flexShrink: 0, textAlign: 'left', alignSelf: 'center',  backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>Est Delay: {props.merch.prod_manufacture_days} Days</Typography>
                        </Grid>
                    :
                        <Typography variant="body2" sx={{ pb: '10px',flexShrink: 0, textAlign: 'left', alignSelf: 'center',  backgroundColor: props.quantity>props.merch.quantity  ? "rgba(255, 165, 0, 0.5)": "transparent" }}>Est Delay: {props.merch.manufacture_days} Days</Typography>
                }                
                </Box>
            </Box>
        </Grid>
    );
}

export default MerchandiceCheckbox

