import { Link } from 'react-router-dom'
import ProductThumb from './ProductThumb'
import { Grid } from '@mui/material';
import api from '../api/companies';
import { fetchCompanyData } from '../../features/viewcompany';
import { viewproductSet } from '../../features/viewproduct';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetCartItem } from '../../features/cartlist';

const ListProducts = ({products}) => {
    const cartList = useAppSelector((state) => state.cartlist)
    const dispatch = useAppDispatch()

    const selected = async (product) => {
        dispatch(resetCartItem());
        dispatch(fetchCompanyData(product.company_id));
        dispatch(viewproductSet(product));
    
    };



    
    return (
        <div className="ListProducts" style={{width: '100%'}}>
            {products.length ? (
                <Grid container justifyContent="center" alignItems="center">
                    
                    {
                        
                        products.map(
                            (product,index)=>(
                                <Grid item xs={6} sm={4} md={3} lg={2}>

                            <Link
                                onClick={()=>{selected(product);}}
                                style={{ textDecoration: 'none' }}
                                to={`/company/${product.company_name_code}/product/${product.id}`}
                            >
                                    <ProductThumb key={index}
                                        company_logo={product.company_logo}
                                        company_name={product.company_name_view}
                                        title={product.title}
                                        name_view={product.name_view}
                                        picture_location={product.picture_location}
                                        describe={product.describe}
                                        primary_color={product.primary_color}
                                        review={product.review}
                                        review_total={product.review_total}
                                        price={product.price}
                                        sold={product.sold}
                                        gifted={product.gifted}
                                        sold_quant={product.sold_quant}
                                        secondary_color={product.secondary_color}
                                    />
                            </Link>
                            </Grid>
                            )
                        )
                    }   
                    <br></br>
                </Grid>
            ) : (
                <p style={{ marginTop: "erem" }}>
                    No Products to display.
                </p>
            )}
        </div>
    )
}


export default ListProducts