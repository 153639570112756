import React from 'react';
import Card from './Card'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// const promise = loadStripe("pk_live_51NCU9UJ85f607jaOdfsHtjiVTUPNm7nrunbj0CJQWGublbTviqpWBgUMj1w6Pw576XAKbdEXRQG79SS28omIfwBN00HnF1m16w");
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Credit = ({close, address_id,tax_id, total,shipment_amount,showQR}) => {
  
  const orderPending = (id) => {
    close(id)
  }

    return (
      <div>
        <Elements stripe={promise}>
          <Card close={(id)=>{orderPending(id)}} showQR={(data)=>{showQR(data)}} address_id={address_id} tax_id={tax_id} shipment_amount={shipment_amount} total={total}/>
        </Elements>
      </div>
  );
}

export default Credit